import React, { useState } from 'react';
import { Grid, Paper, FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useTheme } from '@material-ui/core/styles';
import { formatDistance, formatSpeed, formatHours, formatDate, formatVolume, formatNumber } from '../common/formatter';
import ReportFilter from './ReportFilter';
import ReportLayout from './ReportLayout';
import { useAttributePreference } from '../common/preferences';
import { useTranslation } from '../LocalizationProvider';
import { localizedTextsMap } from '../components/Grid/Grid';

const Filter = ({ setItems }) => {

  const t = useTranslation();
  const [daily, setDaily] = useState(false);
  const [cost, setCost] = useState(0);

  const handleSubmit = async (deviceId, from, to, mail, headers) => {
    const query = new URLSearchParams({
       deviceId, from, to, daily, mail, cost
    });
    const response = await fetch(`/api/reports/trips?${query.toString()}`, { headers });
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (contentType === 'application/json') {

          let data = await response.json();

          let index = 0;
          for (let key in data) {
            data[key].id = index++;
          }
          
          setItems(data);

        } else {
          window.location.assign(window.URL.createObjectURL(await response.blob()));
        }
      }
    }
  };

  return <ReportFilter handleSubmit={handleSubmit}>
    <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          margin="normal"
          value={cost || 0}
          type="number"
          inputProps={{
            maxLength: 13,
            step: "0.1"
          }}
          onChange={(event) => setCost(event.target.value)}
          label={t('reportPriceFuel')}
          variant="filled"
        />
      </Grid>
      
    <Grid item xs={12} sm={2}><br />
        <FormControlLabel
          control={<Checkbox checked={daily} onChange={(e) => setDaily(e.target.checked)} />}
          label={t('reportDaily')}
        />
      </Grid>
  </ReportFilter>;
};

const TripReportPage = () => {
  const theme = useTheme();
  const t = useTranslation();

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');

  const columns = [{
    headerName: t('reportStartTime'),
    field: 'startTime',
    type: 'dateTime',
    width: theme.dimensions.columnWidthDate,
    valueFormatter: ({ value }) => formatDate(value),
  }, {
    headerName: t('reportStartOdometer'),
    field: 'startOdometer',
    type: 'number',
    width: theme.dimensions.columnWidthNumber,
    valueFormatter: ({ value }) => formatDistance(value, distanceUnit, t),
  }, {
    headerName: t('reportStartAddress'),
    field: 'startAddress',
    type: 'string',
    hide: true,
    width: theme.dimensions.columnWidthString,
  }, {
    headerName: t('reportEndTime'),
    field: 'endTime',
    type: 'dateTime',
    width: theme.dimensions.columnWidthDate,
    valueFormatter: ({ value }) => formatDate(value),
  }, {
    headerName: t('reportEndOdometer'),
    field: 'endOdometer',
    type: 'number',
    width: theme.dimensions.columnWidthNumber,
    valueFormatter: ({ value }) => formatDistance(value, distanceUnit, t),
  }, {
    headerName: t('reportEndAddress'),
    field: 'endAddress',
    type: 'string',
    hide: true,
    width: theme.dimensions.columnWidthString,
  }, {
    headerName: t('sharedDistance'),
    field: 'distance',
    type: 'number',
    width: theme.dimensions.columnWidthNumber,
    valueFormatter: ({ value }) => formatDistance(value, distanceUnit, t),
  }, {
    headerName: t('reportAverageSpeed'),
    field: 'averageSpeed',
    type: 'number',
    width: theme.dimensions.columnWidthNumber,
    valueFormatter: ({ value }) => formatSpeed(value, speedUnit, t),
  }, {
    headerName: t('reportMaximumSpeed'),
    field: 'maxSpeed',
    type: 'number',
    width: theme.dimensions.columnWidthNumber,
    valueFormatter: ({ value }) => formatSpeed(value, speedUnit, t),
  }, {
    headerName: t('reportDuration'),
    field: 'duration',
    type: 'string',
    width: theme.dimensions.columnWidthString,
    valueFormatter: ({ value }) => formatHours(value),
  }, {
    headerName: t('reportSpentFuel'),
    field: 'spentFuel',
    type: 'number',
    width: theme.dimensions.columnWidthNumber,
    hide: false,
    valueFormatter: ({ value }) => formatVolume(isNaN(value) ? 0.0 : value, volumeUnit, t),
  }, {
    headerName: t('reportCostFuel'),
    field: 'costFuel',
    type: 'number',
    width: theme.dimensions.columnWidthNumber,
    hide: false,
    valueFormatter: ({ value }) => formatNumber(isNaN(value) ? 0.0 : value, 2),
  }, {
    headerName: t('sharedDriver'),
    field: 'driverName',
    type: 'string',
    width: theme.dimensions.columnWidthString,
    hide: true,
  }];

  const [items, setItems] = useState([]);
  const [pageSize, setPageSize] = React.useState(10);

  console.log(items)

  return (
    <ReportLayout filter={<Filter setItems={setItems} />}>
      <Paper>
        <DataGrid
        rows={items}
        columns={columns}       
        autoHeight
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        pagination
        localeText={localizedTextsMap}
          componentsProps={{
            pagination: {
              labelRowsPerPage: "Linhas por página"
            }
          }}
        />
      </Paper>
    </ReportLayout>
  );
};

export default TripReportPage;
