export const localizedTextsMap = {

    columnMenuUnsort: "Não classificado",
    columnMenuSortAsc: "Ordem crescente",
    columnMenuSortDesc: "Ordem decrescente",
    columnMenuFilter: "Filtro",
    columnMenuHideColumn: "Ocultar",
    columnMenuShowColumns: "Mostrar colunas",
    columnsPanelTextFieldLabel: "Procurar coluna",
    columnsPanelTextFieldPlaceholder: "Nome da coluna",
    columnsPanelShowAllButton: "Mostrar tudo",
    columnsPanelHideAllButton: "Esconder tudo",
    filterPanelInputLabel: "Valor",
    filterPanelInputPlaceholder: "Digite um valor",
    filterPanelOperators: "Operadores",
    filterPanelColumns: "Colunas",
    filterOperatorContains: "Contém",
    filterOperatorEquals: "Igual",
    filterOperatorStartsWith: "Começa com",
    filterOperatorEndsWith: "Termina com",
    filterOperatorIs: "É",
    filterOperatorNot: "Não",
    filterOperatorAfter: "Depois",
    filterOperatorOnOrAfter: "Em ou depois",
    filterOperatorBefore: "Antes",
    filterOperatorOnOrBefore: "Em ou antes",
    filterOperatorIsEmpty: "É vazio",
    filterOperatorIsNotEmpty: "Não é vazio",
    filterValueAny: "Qualquer",
    filterValueTrue: "Verdadeiro",
    filterValueFalse: "Falso",
    noRowsLabel: "Nenhum registro",

    footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} linhas selecionadas`
      : `${count.toLocaleString()} linha selecionada`,

  };

  // https://github.com/mui/mui-x/blob/master/packages/grid/x-data-grid/src/locales/ptBR.ts