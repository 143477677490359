import React, { useState } from 'react';
import { Avatar, Container, TableContainer, TextField, TablePagination, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, Button } from '@material-ui/core';
import { useEffectAsync } from './reactHelper';
import EditIcon from '@material-ui/icons/Edit';
import EditCollectionView from './EditCollectionView';
import OptionsLayout from './settings/OptionsLayout';
import { useTranslation } from './LocalizationProvider';
import HeaderBreadcrumbs from './components/HeaderBreadcrumbs';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
}));

const DevicesView = ({ updateTimestamp }) => {
  const classes = useStyles();
  const t = useTranslation();

  /** pagination */
  const [items, setItems] = useState([]);
  const [itemsAll, setItemsAll] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchField, setSearchField] = useState('');

  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      /** pagination */
      const i = await response.json();
      setItems(i);
      setItemsAll(i);
      setLoading(false);
    }
    
  }, [updateTimestamp]);

  const getPhoto = (item) => {
    let file_photo = (item.category === '' || item.category === undefined || !item.category) ? '/images/icon/default.svg' : `/images/icon/${item.category}.svg`;

    if (item.photo) {
      if (item.photo.length > 0 && item.photo.length < 30) {
        file_photo = `/photos/devices/${item.photo}`;
      }
    }

    return `.${file_photo}`;

  }

  /** pagination */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);

  const setSearch = (value) => {
    setSearchField(value);
    setItems(itemsAll.filter(x =>
      x.name.toUpperCase().includes(value.toUpperCase()) ||
      x.uniqueId.toUpperCase().includes(value.toUpperCase()) ||
      (x.contact ? x.contact.toUpperCase().includes(value.toUpperCase()) : '')
    ));
  }

  return (
    <>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={ 'Veículos' }
          links={[ { name: 'Gerencie seus veículos' }]}
        />
      </Container>
      <TableContainer>
        {/** pagination */}
        <TextField
          margin="normal"
          value={searchField || ''}
          onChange={(event) => setSearch(event.target.value)}
          label={`${t('sharedSearch')}...`}
          variant="filled"
          fullWidth
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.columnAction} />
              <TableCell>{t('sharedName')}</TableCell>
              <TableCell>{t('deviceIdentifier')}</TableCell>
              <TableCell>{t('sharedDeviceName')}</TableCell>
              <TableCell>{t('deviceCategory')}</TableCell>
              <TableCell>{'Cor'}</TableCell>
              <TableCell>{t('sharedDisabled')}</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/** pagination */}
            {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                <Avatar alt={item.name} src={getPhoto(item)} style={{ padding: '2%' }}/>
                </TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.uniqueId}</TableCell>
                <TableCell>{item.contact}</TableCell>
                <TableCell>{t(`category${!item.category ? 'Default' : item.category.replace(/^\w/, (c) => c.toUpperCase())}`)}</TableCell>
                <TableCell>{item.color || ''}</TableCell>
                <TableCell>{item.disabled ? t('sharedYes') : t('sharedNo')}</TableCell>
                <TableCell className={classes.columnAction} padding="none">
                <Button href={`#/device/${item.id}`} style={{ height: '5vh', background: '#000', color: '#FFF', padding: '2vh', fontSize: '2vh' }}><EditIcon style={{ height: 18, width: 18 }} /> Editar</Button>
                </TableCell>
              </TableRow>
            ))}
            {/** pagination */}
            {emptyRows > 0 && (
              <TableRow style={{ height: 72 * emptyRows }}>
                {/*<TableCell align="center" colSpan={12}>{t('sharedRowsEmpty')}</TableCell>*/}
                <TableCell align="center" colSpan={12} />
              </TableRow>
            )}
            {loading && (
              <TableRow style={{ height: 72 * emptyRows }}>
                <TableCell align="center" colSpan={12}>{t('sharedLoading')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/** pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: t('sharedAll'), value: -1 }]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('sharedRowsPerPage')}
      />
    </>
  );
};

const DevicesPage = () => (
  <OptionsLayout>
    <EditCollectionView content={DevicesView} editPath="/device" endpoint="devices" />
  </OptionsLayout>
);

export default DevicesPage;
