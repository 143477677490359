import React, { useState } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Container, Typography, TextField, FormControl, InputLabel, MenuItem, Select, Grid
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditItemView from '../EditItemView';
import { useTranslation } from '../LocalizationProvider';
import usePositionAttributes from '../attributes/usePositionAttributes';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const ComputedAttributePage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const positionAttributes = usePositionAttributes(t);

  const [item, setItem] = useState();
  const [key, setKey] = useState();

  const options = Object.entries(positionAttributes).map(([key, value]) => ({
    key,
    name: value.name,
    type: value.type,
  }));

  const handleChange = (event) => {
    const newValue = event.target.value;
    setKey(newValue);
    const positionAttribute = positionAttributes[newValue];
    if (positionAttribute && positionAttribute.type) {
      setItem({ ...item, attribute: newValue, type: positionAttribute.type });
    } else {
      setItem({ ...item, attribute: newValue });
    }
  };

  return (
    <EditItemView endpoint="attributes/computed" item={item} setItem={setItem}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={'Gerenciar um Atributo'}
          links={[ { name: 'Crie ou edite um atributo calculado' }]}
        />
      </Container>
      {item
        && (
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Configurações</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    margin="normal"
                    value={item.description || ''}
                    onChange={(event) => setItem({ ...item, description: event.target.value })}
                    label={t('sharedDescription')}
                    variant="filled"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="filled" margin="normal" fullWidth>
                    <InputLabel>{t('sharedAttribute')}</InputLabel>
                    <Select
                      value={item.attribute || ''}
                      onChange={handleChange}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.key} value={option.key}>{option.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    value={item.expression || ''}
                    onChange={(event) => setItem({ ...item, expression: event.target.value })}
                    label={t('sharedExpression')}
                    multiline
                    rows={4}
                    variant="filled"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    variant="filled"
                    margin="normal"
                    fullWidth
                    disabled={key in positionAttributes}
                  >
                    <InputLabel>{t('sharedType')}</InputLabel>
                    <Select
                      value={item.type || ''}
                      onChange={(event) => setItem({ ...item, type: event.target.value })}
                    >
                      <MenuItem value="string">{t('sharedTypeString')}</MenuItem>
                      <MenuItem value="number">{t('sharedTypeNumber')}</MenuItem>
                      <MenuItem value="boolean">{t('sharedTypeBoolean')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )
      }
    </EditItemView >
  );
};

export default ComputedAttributePage;
