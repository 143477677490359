import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Swal from 'sweetalert2';

import { useEffectAsync } from './reactHelper';
import OptionsLayout from './settings/OptionsLayout';
import { useTranslation } from './LocalizationProvider';
import { notyMessage, dialogLoading } from './common/dialogs';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
}));

const EditItemView = ({
  children, endpoint, item, setItem,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const t = useTranslation();

  const { id } = useParams();

  useEffectAsync(async () => {
    if (id) {
      const response = await fetch(`/api/${endpoint}/${id}`);
      if (response.ok) {
        setItem(await response.json());
      }
    } else {
      setItem({});
    }
  }, [id]);

  const handleSave = async () => {
    dialogLoading(t('sharedWait'), '');

    let url = `/api/${endpoint}`;
    if (id) {
      url += `/${id}`;
    }

    let values = item;

    // usuarios, veiculos e motoristas
    if (item.photoBase64) {
      values.photo = item.photoBase64;
    }
    delete values.photoBase64;

    if (endpoint === 'drivers' || endpoint === 'groups' || endpoint === 'users' || endpoint === 'devices') {
      if (!values.photo) {
        values.photo = '';
      }
    }

    //revenda
    if (item.logoBase64) {
      values.logo = item.logoBase64;
    }
    delete values.logoBase64;

    if (endpoint === 'devices') {
      values.photoInstall = '';
      if (item.photoInstallBase64) {
        values.photoInstall = item.photoInstallBase64;
      }
      delete values.photoInstallBase64;
    }

    const response = await fetch(url, {
      method: !id ? 'POST' : 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    });
    Swal.close();
    
    if (response.ok) {
      await notyMessage('success', t('successTitle'), t('successMessage'), () => {
        history.goBack();
        //push('/dashboard')
      }, true);
    } else {
      await notyMessage('error', t('errorTitle'), response.statusText, () => { }, true);
    }
  };

  return (
    <OptionsLayout>
      <Container className={classes.container}>
        {children}
        <FormControl fullWidth margin="normal">
          {(endpoint !== 'invoices' && endpoint !== 'checkinvoices') &&
            <div className={classes.buttons}>
              <Button type="button" color="primary" variant="outlined" onClick={() => history.goBack()}>
                {t('sharedCancel')}
              </Button>
              <Button type="button" color="primary" variant="contained" onClick={handleSave}>
                {t('sharedSave')}
              </Button>
            </div>
          }
        </FormControl>
      </Container>
    </OptionsLayout>
  );
};

export default EditItemView;
