import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { sessionReducer as session } from './session';
import { devicesReducer as devices } from './devices';
import { positionsReducer as positions } from './positions';
import { geofencesReducer as geofences } from './geofences';
import { groupsReducer as groups } from './groups';
import { driversReducer as drivers } from './drivers';
import { maintenancesReducer as maintenances } from './maintenances';
/** MOD */
import { revendasReducer as revendas } from './revendas';
import { plansReducer as plans } from './plans';
import { billingsReducer as billings } from './billings';
import { invoicesReducer as invoices } from './invoices';

const reducer = combineReducers({
  session,
  devices,
  positions,
  geofences,
  groups,
  drivers,
  maintenances,
  /** MOD */
  revendas,
  plans,
  billings,
  invoices,
});

export { sessionActions } from './session';
export { devicesActions } from './devices';
export { positionsActions } from './positions';
export { geofencesActions } from './geofences';
export { groupsActions } from './groups';
export { driversActions } from './drivers';
export { maintenancesActions } from './maintenances';
/** MOD */
export { revendasActions } from './revendas';
export { plansActions } from './plans';
export { billingsActions } from './billings';
export { invoicesActions } from './invoices';

export default configureStore({ reducer });
