import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from './LocalizationProvider';
import { notyMessage } from './common/dialogs';
import Swal from 'sweetalert2';

const RemoveDialog = ({
  open, endpoint, itemId, onResult,
}) => {
  const t = useTranslation();

  const handleRemove = async () => {
    const response = await fetch(`/api/${endpoint}/${itemId}`, { method: 'DELETE' });
    Swal.close();
    if (response.ok) {
      await notyMessage('success', t('successTitle'), t('deletedMessage'), () => {}, true);
    } else {
      await notyMessage('error', t('errorTitle'), response.statusText, () => {}, true);
    }
    onResult(true);
  };

  return (
    <Dialog
      open={open}
      onClose={() => { onResult(false); }}
    >
      <DialogContent>
        <DialogContentText>{t('sharedRemoveConfirm')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleRemove}>{t('sharedRemove')}</Button>
        <Button autoFocus onClick={() => onResult(false)}>{t('sharedCancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;
