export const getIsAdmin = (state) => state.session.user?.administrator;

export const getUserId = (state) => state.session.user?.id;

export const getDevices = (state) => Object.values(state.devices.items);

export const getDevice = (id) => (state) => state.devices.items[id];

export const getPosition = (id) => (state) => state.positions.items[id];

/** MOD */

export const getPositions = (state) => Object.values(state.positions.items);

export const getRevendas = (state) => Object.values(state.revendas.items);

export const getServer = (state) => Object.values(state.session.server);

export const getIsManager = (state) => state.session.user?.userLimit === -1;

export const getUsers = (state) => Object.values(state.users.items);

export const getGroup = (id) => (state) => state.groups.items[id];

export const getGroups = (state) => Object.values(state.groups.items);

export const getUserReadOnly = (state) => state.session.user?.readonly || false;

export const getUserLimitCommands = (state) => state.session.user?.limitCommands || false;

export const getCurrentUser = (state) => state.session.user;

export const getPlans = (state) => Object.values(state.plans.items);

export const getBillings = (state) => Object.values(state.billings.items);

export const getDrivers = (state) => Object.values(state.drivers.items);

export const getDriver = (id) => (state) => state.drivers.items[id];

export const getInvoices = (state) => Object.values(state.invoices.items);