import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, TextField, Grid, Typography } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from '../../LocalizationProvider';

const ReportFilter = ({ children, handleSubmit, showOnly }) => {
  const t = useTranslation();

  const devices = useSelector((state) => Object.values(state.devices.items));

  const [deviceId, setDeviceId] = useState(localStorage.getItem('device'));
  const [devicePlate] = useState(localStorage.getItem('plate'));

  const [period, setPeriod] = useState('today');
  const [from, setFrom] = useState(moment().subtract(1, 'hour'));
  const [to, setTo] = useState(moment());

  const filter = createFilterOptions({
    stringify: (option) => option.contact,
  });

  const handleClick = (mail, json) => {
    let selectedFrom;
    let selectedTo;
    switch (period) {
      case 'today':
        selectedFrom = moment().startOf('day');
        selectedTo = moment().endOf('day');
        break;
      case 'yesterday':
        selectedFrom = moment().subtract(1, 'day').startOf('day');
        selectedTo = moment().subtract(1, 'day').endOf('day');
        break;
      case 'thisWeek':
        selectedFrom = moment().startOf('week');
        selectedTo = moment().endOf('week');
        break;
      case 'previousWeek':
        selectedFrom = moment().subtract(1, 'week').startOf('week');
        selectedTo = moment().subtract(1, 'week').endOf('week');
        break;
      case 'thisMonth':
        selectedFrom = moment().startOf('month');
        selectedTo = moment().endOf('month');
        break;
      case 'previousMonth':
        selectedFrom = moment().subtract(1, 'month').startOf('month');
        selectedTo = moment().subtract(1, 'month').endOf('month');
        break;
      default:
        selectedFrom = from;
        selectedTo = to;
        break;
    }

    const accept = json ? 'application/json' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    handleSubmit(
      deviceId,
      selectedFrom.toISOString(),
      selectedTo.toISOString(),
      mail,
      { Accept: accept },
    );
  };

  return (
    
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Autocomplete
          onChange={(_, option) => {
            if (option && option.id) {
            setDeviceId(option.id)
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue) {
              filtered.push({
                key: params.inputValue,
                name: params.inputValue,
              });
            }
            return filtered;
          }}
          options={devices}
          value={devicePlate}
          getOptionLabel={(option) => (option && typeof option === 'object' ? option.contact : option)}
          renderOption={(option) => option.contact}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label={'Veículos'} variant="filled" margin="normal" />
          )}
        />
    </Grid>

      <Grid item xs={12} sm={12}>
        <FormControl variant="filled" fullWidth>
          <InputLabel>{t('reportPeriod')}</InputLabel>
          <Select value={period} onChange={(e) => setPeriod(e.target.value)}>
            <MenuItem value="today">{t('reportToday')}</MenuItem>
            <MenuItem value="yesterday">{t('reportYesterday')}</MenuItem>
            <MenuItem value="thisWeek">{t('reportThisWeek')}</MenuItem>
            <MenuItem value="previousWeek">{t('reportPreviousWeek')}</MenuItem>
            <MenuItem value="thisMonth">{t('reportThisMonth')}</MenuItem>
            <MenuItem value="previousMonth">{t('reportPreviousMonth')}</MenuItem>
            <MenuItem value="custom">{t('reportCustom')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {period === 'custom' && (
      <Grid item xs={12} sm={6}>
        <TextField
          variant="filled"
          label={t('reportFrom')}
          type="datetime-local"
          value={from.format(moment.HTML5_FMT.DATETIME_LOCAL)}
          onChange={(e) => setFrom(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL))}
          fullWidth
        />
      </Grid>
      )}
      {period === 'custom' && (
      <Grid item xs={12} sm={6}>
        <TextField
          variant="filled"
          label={t('reportTo')}
          type="datetime-local"
          value={to.format(moment.HTML5_FMT.DATETIME_LOCAL)}
          onChange={(e) => setTo(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL))}
          fullWidth
        />
      </Grid>
      )}
      {children}
      <Grid item xs={!showOnly ? 4 : 12} sm={!showOnly ? 2 : 6}>
        <Button
          onClick={() => handleClick(false, true)}
          variant="outlined"
          color="secondary"
          fullWidth
        >
          {t('reportShow')}
        </Button>
      </Grid>
      {!showOnly
        && (
        <Grid item xs={4} sm={2}>
          <Button
            onClick={() => handleClick(false, false)}
            variant="outlined"
            color="secondary"
            fullWidth
          >
            {t('reportExport')}
          </Button>
        </Grid>
        )}
      {!showOnly
        && (
        <Grid item xs={4} sm={2}>
          <Button
            onClick={() => handleClick(true, false)}
            variant="outlined"
            color="secondary"
            fullWidth
          >
            <Typography variant="button" noWrap>{t('reportEmail')}</Typography>
          </Button>
        </Grid>
        )}
    </Grid>
  );
};

export default ReportFilter;
