import React from 'react';
import {
  Grid, useMediaQuery, makeStyles
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from './LocalizationProvider';
import CommonPage from './CommonPage';
import { SeverErrorIllustration} from './assets';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  resetPassword: {
    cursor: 'pointer',
  },
}));

const Error504Page = () => {
  const classes = useStyles();
  const theme = useTheme();
  const t = useTranslation();
  
  const revenda = JSON.parse(window.localStorage.getItem('revenda'));


  return (
    <CommonPage>
      <Grid container direction="column" spacing={2}>
        {useMediaQuery(theme.breakpoints.down('md'))
          && (
            <Grid item className={classes.logoContainer}>
              {(revenda)
                && (<img src={`./themes/${revenda.code}/logo.png`} alt="Logomarca" />)
              }
            </Grid>
          )}
        <Grid item>
        <div className="main-error-page">
          <SeverErrorIllustration sx={{ width: 220 }} />
            <h1 className="error-title">
              {t('shared504Wow')} <br />{t('shared504Title')}
            </h1>
            <h2 className="error-subtitle">
            {t('shared504SubTitle')}
            </h2>
          </div>

        </Grid>
      </Grid>
    </CommonPage>
  );
};

export default Error504Page;
