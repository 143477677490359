import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  makeStyles, SvgIcon, Avatar, Accordion,
  AccordionDetails, AccordionSummary, Typography, Divider, Grid
} from '@material-ui/core';
import clsx from 'clsx';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NearMeIcon from '@material-ui/icons/ArrowUpwardTwoTone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import { formatHours, getStatusColor, formatDistance, formatSpeed, formatDate, courseFormatter } from '../common/formatter';
import { useAttributePreference } from '../common/preferences';
import { useTranslation } from '../LocalizationProvider';

import { ReactComponent as IgnitionOnIcon } from '../../public/templates/default/img/ignition-on.svg';
import { ReactComponent as IgnitionOffIcon } from '../../public/templates/default/img/ignition-off.svg';

import { ReactComponent as MotionIcon } from '../../public/templates/default/img/run.svg';
import { ReactComponent as StoppedIcon } from '../../public/templates/default/img/stopped.svg';

import { ReactComponent as BlockedIcon } from '../../public/templates/default/img/blocked.svg';
import { ReactComponent as UnblockedIcon } from '../../public/templates/default/img/unblocked.svg';

import { ReactComponent as AlarmIcon } from '../../public/templates/default/img/warningOn.svg';
import { ReactComponent as offAlarmIcon } from '../../public/templates/default/img/warningOff.svg';

import { useEffectAsync } from '../reactHelper';
import { getCurrentUser } from '../common/selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    bottom: theme.spacing(0),
    right: '0px',
    position: 'absolute',
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0),
      width: theme.spacing(60),
    },
    zIndex: 0,
    [theme.breakpoints.up('lg')]: {
      right: theme.spacing(1.8),
      bottom: theme.spacing(1.5),
      width: '55%',//theme.spacing(80),
    },
  },
  card_header: {
    backgroundColor: '#DCDCDC',
    paddingTop: 5,
    paddingBottom: 5,
    height: 30,
  },
  avatar: {
    backgroundColor: '#fff',
    padding: 12,
    height: 18,
    width: 18,
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    justifyContent: 'space-between',
    width: '100%',
  },
  items_title: {
    fontSize: 11,
    fontWeight: 'bold',
    padding: 3,
  },
  items_value: {
    fontSize: 11,
    fontWeight: 'normal',
    padding: 3,
    alignContent: 'flex-start'
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  terciaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '50%',
  },
  column2: {
    flexBasis: '30%',
  },
  column3: {
    flexBasis: '30%',
  },
  columnIndicators: {
    flexBasis: '40%',
  },
  columnName: {
    flexBasis: '60%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: '1%',//theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  green: {
    color: theme.palette.common.green,
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  red: {
    color: theme.palette.common.red,
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  gray: {
    color: theme.palette.common.gray,
    fontSize: '0.65rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
}));

const BottomMenu = () => {

  const classes = useStyles();
  const t = useTranslation();
  const dateTimeFormat = useAttributePreference('dateTimeFormat', 'YYYY-MM-DD HH:mm:ss');
  const enableMapBottomPanel = useAttributePreference('enableMapBottomPanel', true);
  const currentUser = useSelector(getCurrentUser);

  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [fixTime, setFixTime] = useState('-');

  const [status, setStatus] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [address, setAddress] = useState(null);
  const [speed, setSpeed] = useState('');
  const [geofence, setGeofence] = useState('');
  const [driver, setDriver] = useState('');
  const [distance, setDistance] = useState('');
  const [distanceTotal, setDistanceTotal] = useState('');
  const [hourTotal, setHourTotal] = useState('');
  const [ignition, setIgnition] = useState(false);
  const [motion, setMotion] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [alarm, setAlarm] = useState('');
  const [direction, setDirection] = useState(0);
  const [batteryLevel, setBatteryLevel] = useState(null);
  const [power, setPower] = useState(null);

  const speedUnit = useAttributePreference('speedUnit');
  const distanceUnit = useAttributePreference('distanceUnit');

  //const showInfoBottom = useSelector((state) => { return state.devices.showInfoBottom });

  const selectedDevice = useSelector((state) => {
    if (state.devices.selectedId) {

      window.localStorage.setItem('device', state.devices.selectedId);

      const device = state.devices.items[state.devices.selectedId] || null;
      const position = state.positions.items[state.devices.selectedId] || null;

      window.localStorage.setItem('plate', device.contact);

      if (position) {
        return {
          device: {
            id: state.devices.selectedId,
            name: device.name || '',
            uniqueId: device.uniqueId,
            contact: device.contact || '',
            category: device.category || 'default',
            photo: device.photo || '',
            status: device.status || 'unknown',
          },
          position: {
            latitude: position.latitude,
            longitude: position.longitude,
            address: position.address,
            course: position.course || 0,
            speed: position.speed || 0,

            distance: position.attributes.distance || 0,
            distanceTotal: position.attributes.totalDistance || 0,
            hourTotal: position.attributes.hours || 0,
            motion: position.attributes.motion || false,
            ignition: position.attributes.ignition || false,
            blocked: position.attributes.blocked || false,
            geofence: position.attributes.geofence || '',
            alarm: position.attributes.alarm || '',
            batteryLevel: position.attributes.batteryLevel || '',
            power: position.attributes.power || null,
            powerCut: position.attributes.powerCut || null,
            fixTime: formatDate(position.fixTime, dateTimeFormat) || '-'

          }
        };       
      }
    }

    return null;
  });


  useEffectAsync(async () => { 
    if (selectedDevice) {

      setName(selectedDevice.device.name);
      setContact(selectedDevice.device.contact);
      setStatus(selectedDevice.device.status);            
      setSpeed(formatSpeed(selectedDevice.position.speed, speedUnit, t));
      setDirection(courseFormatter(selectedDevice.position.course, t));
      setDistance(formatDistance(selectedDevice.position.distance, distanceUnit, t));
      setDistanceTotal(formatDistance(selectedDevice.position.distanceTotal, distanceUnit, t));
      setHourTotal(formatHours(selectedDevice.position.hourTotal));
      setLatitude(selectedDevice.position.latitude);
      setLongitude(selectedDevice.position.longitude);

      setIgnition(selectedDevice.position.ignition);
      setMotion(selectedDevice.position.motion);
      setBlocked(selectedDevice.position.blocked !== false);
      setPower(selectedDevice.position.power ? selectedDevice.position.power.toString().substring(0, 4) : null);
      
      
      // ALARMS CHECK
      if(selectedDevice.position.alarm !== null){
      setAlarm(selectedDevice.position.alarm);
      }

      if(selectedDevice.position.powerCut == true){        
      setAlarm('powerCut');
      }   

      else{}


      setGeofence(selectedDevice.position.geofence ? selectedDevice.position.geofence : t('sharedNo'));
      setBatteryLevel(selectedDevice.position.batteryLevel);
      setFixTime(selectedDevice.position.fixTime);
     

      let endereco = 'Indisponível'
      if(selectedDevice.position.address != null)
      endereco = selectedDevice.position.address;
       
      if(selectedDevice.position.address == null){
      const response = await fetch(`/api/server/geocode?latitude=`+selectedDevice.position.latitude+`&longitude=`+selectedDevice.position.longitude+``);   
      endereco = await response.text()       
      }
      
      setAddress(`${endereco.substr(0, 40)} ${endereco.length > 40 ? '...' : ''}`) 
      

          // LOG 
          let profile;
      
          if(currentUser.administrator == true){
          profile = 'administrador';  
          }
      
          if(currentUser.administrator == false){
          profile = 'usuario';  
          }

          fetch(`${localStorage.getItem('log')}?userid=${currentUser.id}&name=${currentUser.name}&login=${currentUser.login}&email=${currentUser.email}&profile=${profile}&action=Rastreando veículo: `+localStorage.getItem('plate')+` `);
                    
    } 
  }, [selectedDevice]);    
  
    


  // GET DRIVER FOR VEIHICLE

    useEffectAsync(async () => { 

    const response = await fetch(`/api/drivers?deviceId=`+localStorage.getItem('device')+``);
    const data = await response.json();
  
    if(data.length !== 0){
    setDriver(data[0].name);
    }

    else{
    setDriver('Indisponível');
    }

  }, [0])

  

  
  const getBatteryStatus = (batteryLevel) => {
    if (batteryLevel >= 71) {
      return 'green';
    }
    if (batteryLevel >= 45 && batteryLevel <= 70) {
      return 'gray';
    }
    else{
      return 'red';
    }
    
  };

  return (
    <div className={classes.container} style={{ display: enableMapBottomPanel ? 'flex' : 'none' }}>
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header">
            <div className={classes.columnIndicators}>
              <Grid item xs={12} spacing={0} >
                <Grid container justifyContent="flex-start" spacing={0} style={{ marginTop: 8 }}>
                  <Grid item>
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      <NearMeIcon style={{ color: '#000', height: 18, width: 18, transform: `rotate(${direction}deg)` }} />
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      <FiberManualRecordIcon style={{ color: getStatusColor(status), height: 18, width: 18 }} /><br />
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      {ignition ?
                        <SvgIcon component={IgnitionOnIcon} style={{ height: 13, width: 13 }} />
                        :
                        <SvgIcon component={IgnitionOffIcon} style={{ height: 15, width: 15 }} />
                      }
                    </Avatar>
                  </Grid>
				  
				  <Grid item alignItems="center" >
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      {batteryLevel ?
                        <BatteryFullIcon className={classes[getBatteryStatus(batteryLevel)]} style={{ height: 18, width: 18 }} />
                        : ''
                      }
                    </Avatar>
                  </Grid>

                  <Grid item>
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      {motion ?
                        <SvgIcon component={MotionIcon} style={{ height: 13, width: 13 }} />
                        :
                        <SvgIcon component={StoppedIcon} style={{ height: 15, width: 15 }} />
                      }
                    </Avatar>
                  </Grid>

                  <Grid item>
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      {blocked ?
                        <SvgIcon component={BlockedIcon} style={{ height: 15, width: 15 }} />
                        :
                        <SvgIcon component={UnblockedIcon} style={{ height: 15, width: 15 }} />
                      }
                    </Avatar>
                  </Grid>

                  <Grid item>
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      {alarm ?
                        <SvgIcon component={AlarmIcon} style={{ height: 15, width: 15 }} />
                        :
                        <SvgIcon component={offAlarmIcon} style={{ height: 15, width: 15 }} />
                      }
                    </Avatar>
                  </Grid>

                  
                </Grid>
              </Grid>
            </div>
            <div className={classes.columnName}>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-start" spacing={1}>
                  <Grid item xs={10}>
                    <Typography className={classes.terciaryHeading} style={{ color: '#000', fontWeight: 'bold', textTransform: 'uppercase' }}>{contact || ''}</Typography>
                    <Typography className={classes.secondaryHeading}>{name || ''}</Typography>                   
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className={classes.terciaryHeading}>{speed}</Typography>
                  </Grid>

                </Grid>
              </Grid>
            </div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails className={classes.details}>
            <div className={classes.column}>

              <div className={classes.items}>
                <span className={classes.items_title}> {t('positionFixTime')}:</span>
                <span className={classes.items_value}> {fixTime}</span>
              </div>
              <div className={classes.items}>
                <span className={classes.items_title}> {t('sharedLocation')}:</span>
                <span className={classes.items_value}> {`${parseFloat(latitude).toFixed(6)}, ${parseFloat(longitude).toFixed(6)}`}</span>
              </div>
              <div className={classes.items}>
                <span className={classes.items_title}> {t('positionAddress')}:</span>
                <span className={classes.items_value}> { address }</span>
              </div>
              <div className={classes.items}>
                <span className={classes.items_title}> {t('sharedDistance')}:</span>
                <span className={classes.items_value}> {distance}</span>
              </div>
              <div className={classes.items}>
                <span className={classes.items_title}> {t('deviceTotalDistance')}:</span>
                <span className={classes.items_value}> {distanceTotal}</span>
              </div>
             
            </div>

            <div className={clsx(classes.column2, classes.helper)}>

              <div className={classes.items}>
                <span className={classes.items_title}> {t('positionHours')}:</span>
                <span className={classes.items_value}> {hourTotal}</span>
              </div>
              <div className={classes.items}>
                <span className={classes.items_title}> {t('positionMotion')}:</span>
                <span className={classes.items_value}> {motion ? t('sharedYes') : t('sharedNo')} </span>
              </div> 
              <div className={classes.items}>
                <span className={classes.items_title}> {t('positionCourse')}:</span>
                <span className={classes.items_value}> {direction}</span>
              </div>     
              <div className={classes.items}>
                <span className={classes.items_title}> Potência:</span>
                <span className={classes.items_value}> { power? ''+power+'V' : 'Indisponível' }</span>
              </div>
              <div className={classes.items}>
                <span className={classes.items_title}> {t('sharedDriver')} :</span>
                <span className={classes.items_value}>{driver} </span>
              </div>             

            </div>

            <div className={clsx(classes.column3, classes.helper)}>

              <div className={classes.items}>
                <span className={classes.items_title}> {t('alarmGeofence')} :</span>
                <span className={classes.items_value}> {geofence}</span>
              </div>
              <div className={classes.items}>
                <span className={classes.items_title}> {t('positionBlocked')}:</span>
                <span className={classes.items_value}> {blocked ? t('sharedYes') : t('sharedNo')}</span>
              </div>
              <div className={classes.items}>
                <span className={classes.items_title}> {t('eventAlarm')}:</span>

                {alarm == 'powerCut' && 
                <span className={classes.items_value} style={{color: 'red'}}> {t(alarm)}</span>
                }

                {alarm !== 'powerCut' && 
                <span className={classes.items_value}> {t(alarm)}</span>
                }
                
              </div> 
              <div className={classes.items}>
                <span className={classes.items_title}> {'Campo'}:</span>
                <span className={classes.items_value}> {'Disponível'}</span>
              </div> 
              <div className={classes.items}>
                <span className={classes.items_title}> {'Campo'}:</span>
                <span className={classes.items_value}> {'Disponível'}</span>
              </div>              
                           
            </div>



          </AccordionDetails>
          {/*<Divider />
          <AccordionActions>
            <Button size="small">Cancel</Button>
            <Button size="small" color="primary">
              Save
          </Button>
          </AccordionActions>
          */}
        </Accordion>
      </div>

    </div>

  );
};


export default BottomMenu;


