import Swal from 'sweetalert2';

export const dialogMessage = (icon, title, text) => {
  Swal.fire({
    title,
    text,
    icon,
    confirmButtonText: 'OK'
  });
};

export const dialogUpdate = async (title, text) => {

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
  });

  Toast.fire({
    title,
    text,
    allowEscapeKey: true,
    allowOutsideClick: true,
    didOpen: () => {
      Swal.showLoading()
    },
    confirmButtonText: 'OK'
  });
};


export const dialogLoading = async (title, text) => {

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
  });

  Toast.fire({
    title,
    text,
    allowEscapeKey: true,
    allowOutsideClick: true,
    didOpen: () => {
      Swal.showLoading()
    },
    confirmButtonText: 'OK'
  });
};

export const dialogQuestion = async (icon, title, text, t, callback) =>
  Swal.fire({
    title,
    text,
    icon,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: t('sharedYes'),
    cancelButtonText: t('sharedNo')
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  })

export const notyMessage = async (icon, title, text, callback, showConfirmButton = false) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  Toast.fire({
    title,
    text,
    icon,
    confirmButtonText: 'OK'
  }).then((result) => {
    if (result.dismiss || result.isConfirmed) {
      callback();
    }
  })
};



export const dialogShared = async (token) =>
  Swal.fire({
    icon:'success',
    title:'Link gerado com sucesso!',
    html:"https://"+localStorage.getItem('domain')+"/?token="+token+"",
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Compartilhar no WhastApp!'
    }).then((result) => {
    if (result.isConfirmed) {

      let text = window.encodeURIComponent(`Para rastrear este dispositivo, acesse pelo o link abaixo\n https://`+localStorage.getItem('domain')+`/?token=`+token+`\n\n *Link válido por 1 hora*`);
      window.open(`https://api.whatsapp.com/send?text=`+text+``,'_blank');

    }
})


export default dialogMessage;
