import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';

import RemoveDialog from './RemoveDialog';
import { useTranslation } from './LocalizationProvider';
import { getUserReadOnly, getIsAdmin, getIsManager } from './common/selectors';
import { dialogQuestion, notyMessage } from './common/dialogs';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const EditCollectionView = ({
  content, editPath, endpoint, disableAdd, filter
}) => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();

  const [selectedId, setSelectedId] = useState(null);
  const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
  const [removeDialogShown, setRemoveDialogShown] = useState(false);
  const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());

  const isAdmin = useSelector(getIsAdmin);
  const isManager = useSelector(getIsManager);
  const userReadOnly = useSelector(getUserReadOnly);

  const menuShow = (anchorId, itemId) => {
    setSelectedAnchorEl(anchorId);
    setSelectedId(itemId);
  };

  const menuHide = () => {
    setSelectedAnchorEl(null);
  };

  const handleAdd = () => {
    history.push(editPath);
    menuHide();
  };

  const handleEdit = () => {
    history.push(`${editPath}/${selectedId}`);
    menuHide();
  };


  const handleViewInvoice = () => {
    history.push(`viewinvoice/${selectedId}`);
    menuHide();
  };

  const handleAccumulators = () => {
    history.push(`/settings/accumulators/${selectedId}`);
    menuHide();
  };

  const handleGenerateInvoice = () => {

    dialogQuestion('warning', t('attention'), t('sharedGenerateInvoice'), t, async () => {
      const response_contract = await fetch(`/api/contracts/${selectedId}`);
      if (response_contract.ok) {

        const values = await response_contract.json();

        const response = await fetch('/api/invoices', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            attributes: {},
            contractid: values.id,
            duedate: values.dueDate,
            statusdate: moment().toISOString(),
            quantity: values.quantity,
            price: values.price,
            paid: 0,
            status: "A",
            description: values.description,
            disabled: false
          }),
        });

        if (response.ok) {
          await notyMessage('success', t('successTitle'), t('successInvoiceMessage'), () => {
          }, true);
        } else {
          await notyMessage('error', t('errorTitle'), response.statusText, () => { }, true);
        }
      } else {
        await notyMessage('error', t('errorTitle'), response_contract.statusText, () => { }, true);
      }
    });
    menuHide();
  };

  const handleCancelInvoice = () => {
    dialogQuestion('warning', t('attention'), t('sharedCancel'), t, async () => {
      const response_invoices = await fetch(`/api/invoices/${selectedId}`);

      if (response_invoices.ok) {

        const values = await response_invoices.json();

        const response = await fetch(`/api/invoices/${selectedId}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            ...values,
            status: "C",
            description: t('sharedInvoiceCanceled'),
            disabled: true
          }),
        });

        if (response.ok) {
          await notyMessage('success', t('successTitle'), t('sharedInvoiceCanceled'), () => {
            document.location.reload(true);
          }, true);
        } else {
          await notyMessage('error', t('errorTitle'), response.statusText, () => { }, true);
        }
      } else {
        await notyMessage('error', t('errorTitle'), response_invoices.statusText, () => { }, true);
      }
    });
    menuHide();
  }

  const handleRemove = () => {
    setRemoveDialogShown(true);
    menuHide();
  };

  const handleRemoveResult = () => {
    setRemoveDialogShown(false);
    setUpdateTimestamp(Date.now());
  };

  const Content = content;

  return (
    <>
      <Content updateTimestamp={updateTimestamp} onMenuClick={menuShow} filter={filter} />
      {(((isAdmin || isManager || !userReadOnly) && !endpoint.includes('invoices')) && !disableAdd)
        && (
          <Fab size="small" color="primary" className={classes.fab} onClick={handleAdd}>
            <AddIcon style={{height:24, width:24}} />
          </Fab>
        )}
      <Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
        {
          endpoint === 'invoices' &&
          <>
            <MenuItem onClick={handleEdit}>{t('sharedView')}</MenuItem>
            <MenuItem onClick={handleCancelInvoice}>{t('sharedCancel')}</MenuItem>
            <Divider />
          </>
        }
        {(endpoint !== 'invoices' && endpoint !== 'checkinvoices') && <MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>
        }
        {
          endpoint === 'checkinvoices' ?
            <MenuItem onClick={handleViewInvoice}>{t('sharedView')}</MenuItem>
            : <MenuItem onClick={handleRemove}>{t('sharedRemove')}</MenuItem>
        }
        {
          endpoint === 'contracts' ?
            <>
              <Divider />
              <MenuItem onClick={handleGenerateInvoice}>{t('sharedGenerateInvoice')}</MenuItem>
            </>
            : null
        }
        {
          endpoint === 'devices' ?
            <>
              <Divider />
              <MenuItem onClick={handleAccumulators}>{t('sharedDeviceAccumulators')}</MenuItem>
            </>
            : null
        }
      </Menu>
      <RemoveDialog open={removeDialogShown} endpoint={endpoint} itemId={selectedId} onResult={handleRemoveResult} />
    </>
  );
};

export default EditCollectionView;
