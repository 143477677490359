import React, { useState } from 'react';
import { makeStyles, Paper, IconButton, Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { useSelector } from 'react-redux';
import NearMeIcon from '@material-ui/icons/ArrowUpwardTwoTone';
import StreetviewIcon from '@material-ui/icons/Streetview';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import LockIcon from '@material-ui/icons/Lock';
import { Share } from '@material-ui/icons';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ReactStreetview from 'react-streetview';
import { formatPosition, getStatusColor, getStatusText, getBatteryStatus, formatSpeed } from '../common/formatter';
import { useAttributePreference } from '../common/preferences';
import { getPosition, getCurrentUser, getIsAdmin } from '../common/selectors';
import { notyMessage, dialogMessage, dialogShared } from '../common/dialogs';

import RemoveDialog from '../RemoveDialog';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },
  paper: {
    width: '330px',
    marginTop: -10,
    marginLeft: -10,
  },
  ...theme.palette.colors,
  listItemContainer: {
    maxWidth: '310px', 
  },
  listItemHeader: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  listItemValue: {
    fontSize: 12,
  },
  card_header: {
    backgroundColor: '#DCDCDC',
    height: 45,
    borderRadius: 0,
  },
  avatar: {
    backgroundColor: '#DCDCDC',
    padding: 15,
    height: 18,
    width: 18,
  },
  blue: {
    width: 36
  }
  
}));


const StatusView = ({
  deviceId, onShowDetails, onShowHistory, onEditClick, translation
}) => {
  const classes = useStyles();
  const [removeDialogShown, setRemoveDialogShown] = useState(false);
  const [activePanel, setActivePanel] = useState(0);
  const device = useSelector((state) => state.devices.items[deviceId]);
  const position = useSelector(getPosition(deviceId));
  const currentUser = useSelector(getCurrentUser);
  const isAdmin = useSelector(getIsAdmin);
  const [customAddress, setCustomAddress] = useState(null);

  const speedUnit = useAttributePreference('speedUnit');

  const revenda = JSON.parse(window.localStorage.getItem('revenda'));

  const handleClick = (e) => {
    e.preventDefault();
    onShowDetails(position.id);
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    onEditClick(deviceId);
  };

  const handleRemoveResult = () => {
    setRemoveDialogShown(false);
  };


  const handleGeocoder = async (position) => {
    if (position && !position.address) {
      const response = await fetch(`/api/server/geocode?latitude=${position.latitude}&longitude=${position.longitude}`);
      if (response.ok) {
        setCustomAddress(await response.text())
      } else {
        setCustomAddress('Indisponível')
      }
    }
  }




  const createShared = async () => {
        
    const response = await fetch(`${localStorage.getItem('shared')}?create&deviceid=${localStorage.getItem('device')}`);
    let shared = await response.json();
    
    if(shared.menssagem == 'sucess'){  
    dialogShared(shared.token);    
    } else {
    dialogMessage('error', 'Atenção', 'Não foi possível gerar um token de compartilhamento!'); 
    }

      
    
    
  // LOG 

  let profile;

  if(currentUser.administrator == true){
  profile = 'administrador';  
  }

  if(currentUser.administrator == false){
  profile = 'usuario';  
  }
  
  fetch(`${localStorage.getItem('log')}?userid=${currentUser.id}&name=${currentUser.name}&login=${currentUser.login}&email=${currentUser.email}&profile=${profile}&action=Criou um link de compartilhamento para o veículo: `+localStorage.getItem('device')+``);

  }
  

  const getPhoto = (item) => {
    let file_photo = item.category === '' || item.category === undefined || !item.category ? '/images/icon-default.png' : `/images/icon-${item.category}.png`;

    if (item.photo) {
      if (item.photo.preview) {
        return item.photo;
      } else {
        if (item.photo.length > 0 && item.photo.length < 30) {
          file_photo = `/photos/devices/${item.photo}`;
        }
      }
    }
    return `.${file_photo}`;
  }

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <Grid container direction="column">
          <Grid item>
            <Card className={classes.root}>
              <CardActionArea>
                <CardHeader className={classes.card_header}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      <NearMeIcon style={{ color: '#000', height: 22, width: 22, transform: `rotate(${position.course}deg)` }} />
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label="settings">&nbsp;</IconButton>
                  }
                  title={device.contact}
                  subheader={device.name}
                />
                <CardContent style={{
                  marginTop: 0,
                  padding: 0,
                }}>

                </CardContent>
              </CardActionArea>
              <CardActions>
                { /** info */
                  activePanel === 0 &&
                  <Grid container direction="column">
                    <Grid item>
                      <List>

                        <ListItem classes={{ container: classes.listItemContainer }} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                            <ListItemText primary={'Nº do equipamento'}
                              classes={{ primary: classes.listItemHeader }} />
                            <ListItemSecondaryAction
                              classes={{ primary: classes.listItemValue }}>
                              <span>{device.uniqueId}</span>
                            </ListItemSecondaryAction>
                          </ListItem>
                          
                        <ListItem classes={{ container: classes.listItemContainer }} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                          <ListItemText primary={translation('deviceStatus')}
                            classes={{ primary: classes.listItemHeader }} />
                          <ListItemSecondaryAction
                            classes={{ primary: classes.listItemValue }}>
                            <span style={{ color: getStatusColor(device.status) }}>{getStatusText(device.status, translation)}</span>
                          </ListItemSecondaryAction>
                        </ListItem>    

                        <ListItem classes={{ container: classes.listItemContainer }} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                          <ListItemText primary='Ignição'
                            classes={{ primary: classes.listItemHeader }} />
                          <ListItemSecondaryAction
                            classes={{ primary: classes.listItemValue }}>
                            {position.attributes.ignition ? 'Ligada' : 'Desligada'}
                          </ListItemSecondaryAction>
                        </ListItem>
                  
                        <ListItem classes={{ container: classes.listItemContainer }} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                          <ListItemText primary={translation('positionSpeed')}
                            classes={{ primary: classes.listItemHeader }} />
                          <ListItemSecondaryAction
                            classes={{ primary: classes.listItemValue }}>
                            {formatSpeed(position.speed, speedUnit, translation)}
                          </ListItemSecondaryAction>
                        </ListItem>

                        {position.attributes.batteryLevel && (
                          <ListItem classes={{ container: classes.listItemContainer }} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                            <ListItemText primary={translation('positionBattery')}
                              classes={{ primary: classes.listItemHeader }} />
                            <ListItemSecondaryAction
                              classes={{ primary: classes.listItemValue }}>
                              <span style={{color: getBatteryStatus(position.attributes.batteryLevel) }}>
                                {formatPosition(position.attributes.batteryLevel, 'batteryLevel', translation)}
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )}                      

                        <ListItem classes={{ container: classes.listItemContainer }} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                          <ListItemText primary={translation('movement')}
                            classes={{ primary: classes.listItemHeader }} />
                          <ListItemSecondaryAction
                            classes={{ primary: classes.listItemValue }}>
                            {position.attributes.motion ? translation('sharedYes') : translation('sharedNo')}
                          </ListItemSecondaryAction>
                        </ListItem>

                        {position.attributes.blocked && (
                          <ListItem classes={{ container: classes.listItemContainer }} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                            <ListItemText primary={translation('positionBlocked')}
                              classes={{ primary: classes.listItemHeader }} />
                            <ListItemSecondaryAction
                              classes={{ primary: classes.listItemValue }}>
                              {position.attributes.blocked ? translation('sharedYes') : translation('sharedNo')}
                            </ListItemSecondaryAction>
                          </ListItem>
                        )}

                        {position.attributes.alarm && (
                          <ListItem classes={{ container: classes.listItemContainer }} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                            <ListItemText primary={translation('positionAlarm')}
                              classes={{ primary: classes.listItemHeader }} />
                            <ListItemSecondaryAction
                              classes={{ primary: classes.listItemValue }}>
                              {position.attributes.alarm ? position.attributes.alarm : translation('sharedNo')}
                            </ListItemSecondaryAction>
                          </ListItem>
                        )}                        
                  
                      {/* ENDEREÇO NOMINATIM */}

                      <ListItem classes={{ container: classes.listItemContainer }} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                          <ListItemText primary={translation('positionAddress')}
                            classes={{ primary: classes.listItemHeader }} />
                          <ListItemSecondaryAction
                            classes={{ primary: classes.listItemValue }}>
                            &nbsp;
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem classes={{ container: classes.listItemContainer }} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                          <ListItemText primary={
                            position.address ?
                              position.address :
                              <Link href="#" onClick={async () => handleGeocoder(position)}>
                                {customAddress || translation('sharedShowAddress')}
                              </Link>
                          }
                            classes={{ primary: classes.listItemValue }} />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>                  
                  
                }
                {
                  activePanel === 1 &&
                  <div style={{ width: '100%', height: '210px', backgroundColor: '#eeeeee', verticalAlign: 'center' }}>
                    <img src={getPhoto(device)} style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'stretch',
                      overflow: 'hidden',
                    }} />
                  </div>
                }
                {
                  activePanel === 2 &&
                  <div style={{
                    width: '100%',
                    height: '210px',
                    backgroundColor: '#eeeeee'
                  }}>
                    <ReactStreetview
                      apiKey={revenda.googlekey}
                      streetViewPanoramaOptions={{
                        position: { lat: position.latitude, lng: position.longitude },
                        pov: { heading: position.course, pitch: 0 },
                        zoom: 1
                      }}
                    />
                  </div>
                }
              </CardActions>
            </Card>
          </Grid>
          <Grid item container style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item>
              <IconButton onClick={handleClick} className={classes.blue}>
                <InfoIcon style={{ height: 18, width: 18 }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={onShowHistory} className={classes.blue}>
                <RotateLeftIcon style={{ height: 18, width: 18 }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => {
                if (revenda.googlekey) {
                  setActivePanel(2);
                } else {
                  window.open(`http://maps.google.com/maps?q=&layer=c&cbll=${position.latitude},${position.longitude}`, '_blank');
                }
              }} className={classes.blue}>
                <StreetviewIcon style={{ height: 18, width: 18 }} />
              </IconButton>
            </Grid>

            {(isAdmin || currentUser.attributes.enableDashboard) &&
              <Grid item>
                <IconButton onClick={handleEditClick} className={classes.blue}>
                  <EditIcon style={{ height: 18, width: 18 }} />
                </IconButton>
              </Grid>
            }

            {(currentUser.attributes.enableShared) &&

                <Grid item>
                  <IconButton className={classes.blue} onClick={createShared}>
                    <Share style={{ height: 18, width: 18 }} />
                  </IconButton>
                </Grid>

            }


            {(currentUser.attributes.enableLock && device.attributes.enableLockApp) &&
              <>
                <Grid item>
                  <IconButton onClick={async () => {

                    const response = await fetch('/api/commands/send', {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json; charset=utf-8' },
                      body: JSON.stringify({
                        deviceId: position.deviceId,
                        type: 'engineStop'
                      }),
                    });

                    if (response.ok) {
                      await dialogMessage('success', translation('successTitle'), response.status === 202 ? translation('commandQueued') : translation('commandSent'), () => { }, true);
                    } else {
                      var str = String(response.statusText);

                      if (str.search(/supported/i) > 0) {
                        await notyMessage('error', translation('errorTitle'), translation('commandNotSupported'), () => { }, true);
                      } else {
                        await notyMessage('error', translation('errorTitle'), response.statusText, () => { }, true);
                      }
                    }
                  }} className={classes.blue}>
                    <LockIcon style={{ color: 'red', height: 18, width: 18 }} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={async () => {

                    const response = await fetch('/api/commands/send', {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json; charset=utf-8' },
                      body: JSON.stringify({
                        deviceId: position.deviceId,
                        type: 'engineResume'
                      }),
                    });

                    if (response.ok) {
                      await dialogMessage('success', translation('successTitle'), response.status === 202 ? translation('commandQueued') : translation('commandSent'), () => { }, true);
                    } else {
                      var str = String(response.statusText);

                      if (str.search(/supported/i) > 0) {
                        await notyMessage('error', translation('errorTitle'), translation('commandNotSupported'), () => { }, true);
                      } else {
                        await notyMessage('error', translation('errorTitle'), response.statusText, () => { }, true);
                      }
                    }
                  }} className={classes.blue}>
                    <LockOpenIcon style={{ color: 'blue', height: 18, width: 18 }} />
                  </IconButton>
                </Grid>
              </>
            }
          </Grid>
        </Grid>
      </Paper>
      <RemoveDialog open={removeDialogShown} endpoint="devices" itemId={deviceId} onResult={handleRemoveResult} />
    </>
  );
};

export default StatusView;
