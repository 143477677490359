import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { map } from './Map';

const SelectedDeviceMap = () => {
  //const dispatch = useDispatch();
  const [lastSelected, setLastSelected] = useState(null);
  const [zoomed, setZoomed] = useState(false);

  const mapCenter = useSelector((state) => {
    if (state.devices.selectedId) {
      const position = state.positions.items[state.devices.selectedId] || null;
      if (position) {
        if (lastSelected !== state.devices.selectedId) {
          setLastSelected(state.devices.selectedId);
          setZoomed(false);
        }

        if (state.devices.selectedId && !zoomed) {
          map.jumpTo({ center: [position.longitude, position.latitude], zoom: 15 });
          setZoomed(true)
        }

        return { deviceId: state.devices.selectedId, position: [position.longitude, position.latitude] };
      }
    }
    return null;
  });

  useEffect(() => {
    if (mapCenter) {
      //      map.easeTo({ center: mapCenter.position });
      //  map.jumpTo({ center: mapCenter.position, zoom: 15 });

    }
  }, [mapCenter]);

  return null;
};

export default SelectedDeviceMap;
