const coordinatePrecision = 6;

export const coordinatesFormat = [
  {
    key: 'dd',
    value: 'sharedDecimalDegrees'
  },
  {
    key: 'ddm',
    value: 'sharedDegreesDecimalMinutes'
  },
  {
    key: 'dms',
    value: 'sharedDegreesMinutesSeconds'
  }
];

export const formatCoordinateValue = (key, value, unit) => {
  let hemisphere;
  let degrees;
  let minutes;
  let seconds;

  if (key === 'latitude') {
    hemisphere = value >= 0 ? 'N' : 'S';
  } else {
    hemisphere = value >= 0 ? 'E' : 'W';
  }
  switch (unit) {
    case 'ddm':
      value = Math.abs(value);
      degrees = Math.floor(value);
      minutes = (value - degrees) * 60;
      return `${degrees}° ${minutes.toFixed(coordinatePrecision)}' ${hemisphere}`;
    case 'dms':
      value = Math.abs(value);
      degrees = Math.floor(value);
      minutes = Math.floor((value - degrees) * 60);
      seconds = Math.round((value - degrees - minutes / 60) * 3600);
      return `${degrees}° ${minutes}' ${seconds}" ${hemisphere}`;
    default:
      return `${value.toFixed(coordinatePrecision)}°`;
  }
};
  