import React, { useState, useCallback } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, makeStyles, Container, Typography, TextField, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import EditItemView from '../EditItemView';
import EditAttributesView from '../attributes/EditAttributesView';
import { useTranslation } from '../LocalizationProvider';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { UploadAvatar } from '../components/upload';
import { fData } from '../utils/formatNumber';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const DriverPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();
 

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleDrop = useCallback(
    async (acceptedFiles, user) => {
      const file = acceptedFiles[0];
      if (file) {
        setItem({
          ...user,
          photo: {
            ...file,
            preview: URL.createObjectURL(file)
          },
          photoBase64: await toBase64(file)
        });
      }
    },
    [setItem]
  );

  const getPhoto = (item) => {
    let file_photo = 'default.png';

    if (!item.photo) { }
    else if (typeof item.photo === 'object') {
      return item.photo;
    } else {
      if (item.photo.length > 0 && item.photo.length < 30) {
        file_photo = item.photo;
      }
    }
    return `./photos/drivers/${file_photo}?${Math.floor(Math.random() * (50000 - 10000 + 1) + 10000)}`;
  }

  return (
    <EditItemView endpoint="drivers" item={item} setItem={setItem}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
           heading={'Motorista'}
           links={[ { name: 'Cadastre ou gerencie as informações de um motorista' }]}
        />
      </Container>
      {item
        && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedRequired')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>

                    <Box sx={{ mb: 12 }}>
                      <UploadAvatar
                        accept="image/*"
                        file={getPhoto(item)}
                        maxSize={3145728}
                        onDrop={(acceptedFiles) => handleDrop(acceptedFiles, item)}
                        caption={''}
                      />
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            style={{
                              mt: 12,
                              mx: 'auto',
                              display: 'block',
                              textAlign: 'center',
                              color: 'text.secondary'
                            }}
                          >
                            {t('sharedAllowed')}  *.jpeg, *.jpg, *.png, *.gif
                                <br /> {t('sharedMaxSize')} {fData(3145728)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        margin="normal"
                        value={item.name || ''}
                        onChange={(event) => setItem({ ...item, name: event.target.value })}
                        label={t('sharedName')}
                        variant="filled"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        margin="normal"
                        value={item.uniqueId || ''}
                        onChange={(event) => setItem({ ...item, uniqueId: event.target.value })}
                        label={t('deviceIdentifier')}
                        variant="filled"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedPreferences')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container spacing={3}>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.email || ''}
                      onChange={(event) => setItem({ ...item, email: event.target.value })}
                      label={t('sharedEmail')}
                      variant="filled"
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.phone || ''}
                      onChange={(event) => setItem({ ...item, phone: event.target.value })}
                      label={t('sharedPhone')}
                      variant="filled"
                    />
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.contact || ''}
                      onChange={(event) => setItem({ ...item, contact: event.target.value })}
                      label={t('deviceContact')}
                      variant="filled"
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.address || ''}
                      onChange={(event) => setItem({ ...item, address: event.target.value })}
                      label={t('sharedAddress')}
                      variant="filled"
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.neighborhood || ''}
                      onChange={(event) => setItem({ ...item, neighborhood: event.target.value })}
                      label={t('sharedNeighborhood')}
                      variant="filled"
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.city || ''}
                      onChange={(event) => setItem({ ...item, city: event.target.value })}
                      label={t('sharedCity')}
                      variant="filled"
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.state || ''}
                      onChange={(event) => setItem({ ...item, state: event.target.value })}
                      label={t('sharedState')}
                      variant="filled"
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.postalcode || ''}
                      onChange={(event) => setItem({ ...item, postalcode: event.target.value })}
                      label={t('sharedPostalCode')}
                      variant="filled"
                    />
                  </Grid>

                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedDriverLicence')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.document || ''}
                      onChange={(event) => setItem({ ...item, document: event.target.value })}
                      label={t('sharedDocumentCNH')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.documentcategory || ''}
                      onChange={(event) => setItem({ ...item, documentcategory: event.target.value })}
                      label={t('deviceCategory')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.documentValidAt || ''}
                      onChange={(event) => setItem({ ...item, documentValidAt: event.target.value })}
                      label={t('sharedDocumentValidDate')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.bloodtype || ''}
                      onChange={(event) => setItem({ ...item, bloodtype: event.target.value })}
                      label={t('sharedBloodType')}
                      variant="filled"
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedAttributes')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <EditAttributesView
                  attributes={item.attributes}
                  setAttributes={(attributes) => setItem({ ...item, attributes })}
                  definitions={{}}
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
    </EditItemView>
  );
};

export default DriverPage;
