import React from 'react';
import {
  Grid, useMediaQuery, makeStyles, Button
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from './LocalizationProvider';
import StartPage from './StartPage';
import { PageNotFoundIllustration} from './assets';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  resetPassword: {
    cursor: 'pointer',
  },
}));

const Error404Page = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const t = useTranslation();

  const revenda = JSON.parse(window.localStorage.getItem('revenda'));

  const handleOnClick = async (event) => {
    event.preventDefault();
    history.goBack()
  };

  return (
    <StartPage>
      <Grid container direction="column" spacing={2}>
        {useMediaQuery(theme.breakpoints.down('md'))
          && (
            <Grid item className={classes.logoContainer}>
              {(revenda)
                && (<img src={`./themes/${revenda.code}/logo.png`} alt="Logomarca" />)
              }
            </Grid>
          )}
        <Grid item>
          <div className="main-error-page">
          <PageNotFoundIllustration sx={{ width: 220 }} />
            <h1 className="error-title">
              {t('shared404Wow')} <br />{t('shared404Title')}
            </h1>
            <h2 className="error-subtitle">
            {t('shared404SubTitle')}
            </h2>
            <Button color="inherit" onClick={handleOnClick}>{t('sharedGoBack')}</Button>
          </div>

        </Grid>
      </Grid>
    </StartPage>
  );
};

export default Error404Page;
