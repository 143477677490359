import React, { useState } from 'react';
import { useTheme } from "@material-ui/styles";
import { ResponsiveContainer, AreaChart, Area, PieChart, Pie, Cell } from "recharts";
import moment from 'moment';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper, Box, Container, Grid, LinearProgress, Tabs, Tab,
  TableContainer, Table, TextField, TablePagination, TableRow, TableCell,
  TableHead, TableBody, makeStyles, Chip, Button
} from '@material-ui/core';

import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Widget from "../components/Widget";
import { Typography } from "../components/Wrappers";
import Dot from "../components/Dot";

//import BigStat from "../components/BigStat/BigStat";
import { getIsAdmin, getIsManager, getDevices, getPositions, getCurrentUser } from '../common/selectors';
import { formatDate, getStatusText } from '../common/formatter';
import { useAttributePreference } from '../common/preferences';
import { dialogUpdate } from '../common/dialogs';
import { useEffectAsync } from '../reactHelper';


const states = {
  online: "success",
  unknown: "warning",
  offline: "error",
};

const conditions = {
  true: "success",
  false: "default",
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const DashboardPage = () => {
  const classes = useStyles();
  var theme = useTheme();

  const t = useTranslation();
  const isAdmin = useSelector(getIsAdmin);
  const isManager = useSelector(getIsManager);

  const devices = useSelector(getDevices);
  const positions = useSelector(getPositions);
  const currentUser = useSelector(getCurrentUser);

  const dateTimeFormat = useAttributePreference('dateTimeFormat', 'DD/MM/YY HH:mm:ss');

  const [deviceStatusChartData, setDeviceStatusChartData] = useState([]);

  const [statistics, setItemsStatistics] = useState([]);
  const [users, setUsers] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);

  const [devicesList, setDevicesList] = useState([]);
  const [devicesListAll, setDevicesListAll] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchField, setSearchField] = useState('');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };



  //INITIALIZE DATA
  
  useEffectAsync(async () => {

    const vehicles = await fetch('/api/devices');
    let dados = await vehicles.json()
    
    setDeviceStatusChartData([
      { id: 0, name: t('deviceStatusOnline'), value: dados.filter((d) => d.status === 'online').length, color: "success" },
      { id: 1, name: t('deviceStatusOffline'), value: dados.filter((d) => d.status === 'offline').length, color: "error" },
      { id: 2, name: t('deviceStatusUnknown'), value: dados.filter((d) => d.status === 'unknown').length, color: "warning" }
    ]);  

    let list = [];

    dados.forEach(device => {
      const { id, name, contact, uniqueId, march, model, lastUpdate, status } = device;
      const idx = positions.findIndex(p => p.deviceId === id);

      list.push({
        id,
        name,
        uniqueId,
        contact: contact || '',
        march: march || '',
        model: model || '',
        lastUpdate: formatDate(lastUpdate, dateTimeFormat),
        motion: idx > -1 ? positions[idx].attributes.motion : false,
        ignition: idx > -1 ? positions[idx].attributes.ignition : false,
        alarm: idx > -1 ? positions[idx].attributes.alarm ? positions[idx].attributes.alarm : '' : '',
        blocked: idx > -1 ? positions[idx].attributes.blocked ? positions[idx].attributes.blocked : false : false,
        status: status || 'unknown',
        
      })
    });

    setDevicesList(list);
    setDevicesListAll(list);
    setLoading(false);

  }, [])

  useEffectAsync(async () => {
    const selectedFrom = moment().startOf('month');
    const selectedTo = moment().endOf('month');

    const query = new URLSearchParams({ from: selectedFrom.toISOString(), to: selectedTo.toISOString() });
    const response = await fetch(`/api/statistics?${query.toString()}`, { Accept: 'application/json' });
    if (response.ok) {
      const result = await response.json();

      let activeUsers = 0;
      let activeDevices = 0;
      let messagesReceived = 0;

      let usersChartData = [];
      let devicesChartData = [];
      let messagesChartData = [];

      for (let i = 0; i < result.length; i++) {
        let stats = result[i];

        activeUsers += stats.activeUsers;
        activeDevices += stats.activeDevices;
        messagesReceived += stats.messagesReceived;

        usersChartData.push({ value: stats.activeUsers })
        devicesChartData.push({ value: stats.activeDevices })
        messagesChartData.push({ value: stats.messagesReceived })
      }

      setItemsStatistics({
        activeUsers: Math.round(activeUsers / result.length),
        activeDevices: Math.round(activeDevices / result.length),
        messagesReceived,
        chart: {
          usersChartData,
          devicesChartData,
          messagesChartData,
        }
      });
    }
  }, [isAdmin]);

  useEffectAsync(async () => {
    const response = await fetch('/api/users');
    if (response.ok) {
      setUsers(await response.json());
    }
  }, [isAdmin]);


  /** pagination */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, devicesList.length - page * rowsPerPage);

  const setSearch = (value) => {
    setSearchField(value);
    setDevicesList(devicesListAll.filter(x =>
      x.name.toUpperCase().includes(value.toUpperCase()) ||
      (x.contact ? x.contact.toUpperCase().includes(value.toUpperCase()) : '') ||
      (x.march ? x.march.toUpperCase().includes(value.toUpperCase()) : '') ||
      (x.model ? x.model.toUpperCase().includes(value.toUpperCase()) : '') ||
      (x.phone ? x.phone.toUpperCase().includes(value.toUpperCase()) : '') ||
      x.uniqueId.toUpperCase().includes(value.toUpperCase()) ||
      x.status.toUpperCase().includes(value.toUpperCase()) 
    ));
  }



  const getData = async () => {

    dialogUpdate('Aguarde', 'Os dados estão sendo atualizados...')

    let vehicles = await fetch('/api/devices');
    vehicles = await vehicles.json()
    
    setDeviceStatusChartData([
      { id: 0, name: t('deviceStatusOnline'), value: vehicles.filter((d) => d.status === 'online').length, color: "success" },
      { id: 1, name: t('deviceStatusOffline'), value: vehicles.filter((d) => d.status === 'offline').length, color: "error" },
      { id: 2, name: t('deviceStatusUnknown'), value: vehicles.filter((d) => d.status === 'unknown').length, color: "warning" }
    ]);  

  
    let position = await fetch('/api/positions');
    position = await position.json()

    let list = [];

    vehicles.forEach(device => {
      const { id, name, contact, uniqueId, march, model, lastUpdate, status } = device;
      const idx = position.findIndex(p => p.deviceId === id);

      list.push({
        id,
        name,
        uniqueId,
        contact: contact || '',
        march: march || '',
        model: model || '',
        lastUpdate: formatDate(lastUpdate, dateTimeFormat),
        motion: idx > -1 ? position[idx].attributes.motion : false,
        ignition: idx > -1 ? positions[idx].attributes.ignition : false,
        alarm: idx > -1 ? position[idx].attributes.alarm ? position[idx].attributes.alarm : '' : '',
        blocked: idx > -1 ? position[idx].attributes.blocked ? position[idx].attributes.blocked : false : false,
        status: status || 'unknown',
        
      })
    });

    setDevicesList(list);
    setDevicesListAll(list);
    setLoading(false);

  }



  return (
    <OptionsLayout>
      <Container maxWidth="lg" className={classes.container}>
        <HeaderBreadcrumbs
          heading={t('sharedDashboard')}
          links={[{ name: 'Resumo das informações' }]}
        />
      </Container>

      <Paper style={{ flexGrow: 1, }}>

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          
        <Tab label={'informações'} {...a11yProps(0)} /></Tabs>  
        
        <br></br>
      
        <TabPanel value={tabValue} index={0} >
    
          {(isManager) && <>
            <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <Widget
                  title={<div style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around', textAlign: 'center' }}>
                  <Typography variant="h6" color="textSecondary" noWrap style={{ flex: 1, color: 'green' }}>{t('deviceStatusOnline')}</Typography>
                  </div>}
                  upperTitle
                  disableWidgetMenu
                  bodyClass={classes.fullHeightBody}
                  className={classes.card}
                >

                  <div className={classes.visitsNumberContainer}>
                    <Grid container item alignItems={"center"}>
                      <Grid item xs={12}>
                        <Typography variant="h2" weight="medium" noWrap style={{ textAlign: 'center' }}>
                          {devices.filter((d) => d.status === 'online').length || 0}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Widget>
              </Grid>

              <Grid item lg={3} md={8} sm={6} xs={12}>
                <Widget
                  title={<div style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around', textAlign: 'center' }}>
                  <Typography variant="h6" color="textSecondary" noWrap style={{ flex: 1, color: 'red' }}>{t('deviceStatusOffline')}</Typography>
                  </div>}
                  upperTitle
                  disableWidgetMenu
                  bodyClass={classes.fullHeightBody}
                  className={classes.card}
                >

                  <div className={classes.visitsNumberContainer}>
                    <Grid container item alignItems={"center"}>
                      <Grid item xs={12}>
                        <Typography variant="h2" weight="medium" noWrap style={{ textAlign: 'center' }}>
                          {devices.filter((d) => d.status === 'offline').length || 0}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Widget>
              </Grid>

              <Grid item lg={3} md={8} sm={6} xs={12}>
                <Widget
                  title={<div style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', textAlign: 'center' }}>
                  <Typography variant="h6" color="textSecondary" noWrap style={{ flex: 1, color: 'black' }}>{t('deviceStatusUnknown')}</Typography>
                  </div>}
                  upperTitle
                  disableWidgetMenu
                  bodyClass={classes.fullHeightBody}
                  className={classes.card}
                >

                  <div className={classes.visitsNumberContainer}>
                    <Grid container item>
                      <Grid item xs={12}>
                        <Typography variant="h2" weight="medium" noWrap style={{ textAlign: 'center' }}>
                          {devices.filter((d) => d.status === 'unknown').length || 0}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Widget>
              </Grid>


              <Grid item lg={2} md={4} sm={6} xs={12}>
                <Widget
                  title={<div style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around', textAlign: 'center' }}>
                  <Typography variant="h6" color="textSecondary" noWrap style={{ flex: 1, color: 'blue' }}>{t('movement')}</Typography>
                  </div>}
                  upperTitle
                  disableWidgetMenu
                  bodyClass={classes.fullHeightBody}
                  className={classes.card}
                >

                  <div className={classes.visitsNumberContainer}>
                    <Grid container item alignItems={"center"}>
                      <Grid item xs={12}>
                        <Typography variant="h2" weight="medium" noWrap style={{ textAlign: 'center' }}>
                          {positions.filter((p) => p.attributes.motion === true).length}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Widget>
              </Grid>
            </Grid>
          </>}


      {/** User admin */}
          {(isAdmin) && <>
            <Grid container spacing={2}>
                          
              <Grid item lg={3} md={8} sm={6} xs={12}>
                <Widget
                  title={<>
                    <Typography variant="h6" color="textSecondary" noWrap >
                      {t('sharedObjects')}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" noWrap >
                      &nbsp;
              </Typography>
                  </>}
                  upperTitle
                  disableWidgetMenu
                  className={classes.card}
                  bodyClass={classes.fullHeightBody}
                >
                  <div className={classes.performanceLegendWrapper}>
                    <div className={classes.legendElement}>
                      <Dot color="warning" />
                      <Typography
                        color="text"
                        colorBrightness="secondary"
                        className={classes.legendElementText}
                      >
                        {t('settingsUsers')}
                      </Typography>
                    </div>
                    <div className={classes.legendElement}>
                      <Dot color="primary" />
                      <Typography
                        color="text"
                        colorBrightness="secondary"
                        className={classes.legendElementText}
                      >
                        {t('deviceTitle')}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.progressSection}>
                    <Typography
                      size="md"
                      color="text"
                      colorBrightness="secondary"
                      className={classes.progressSectionTitle}
                    >
                      {devices.length || 0}
                    </Typography>
                    {devices.length > 0 &&
                      <LinearProgress
                        variant={currentUser.deviceLimit > 0 ? "determinate" : "indeterminate"}
                        value={Math.round(1 - (currentUser.deviceLimit / devices.length)) * 100}
                        classes={{ barColorPrimary: classes.progressBarPrimary }}
                        className={classes.progress}
                      />
                    }
                  </div>
                  <div>
                    <Typography
                      size="md"
                      color="text"
                      colorBrightness="secondary"
                      className={classes.progressSectionTitle}
                    >
                      {users.length || 0}
                    </Typography>
                    {users.length > 0 &&
                      <LinearProgress
                        variant={currentUser.userLimit > 0 ? "determinate" : "indeterminate"}
                        value={Math.round(1 - (currentUser.userLimit / users.length)) * 100}
                        classes={{ barColorPrimary: classes.progressBarWarning }}
                        className={classes.progress}
                      />
                    }
                  </div>
                </Widget>
              </Grid>
              <Grid item lg={5} md={8} sm={6} xs={12}>
                <Widget
                  title={<>
                    <Typography variant="h6" color="textSecondary" noWrap >
                      {t('statisticsTitle')}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" noWrap >
                      (Média Mês)
                </Typography>
                  </>}
                  upperTitle
                  disableWidgetMenu
                  className={classes.card}
                  bodyClass={classes.fullHeightBody}
                >
                  <div className={classes.serverOverviewElement}>
                    <Typography
                      color="text"
                      colorBrightness="secondary"
                      className={classes.serverOverviewElementText}
                      noWrap
                    >
                      <Typography
                        color="text"
                        colorBrightness="secondary">
                        {t('statisticsActiveUsers')}
                      </Typography>
                      {statistics.activeUsers || 0}
                    </Typography>
                    <div className={classes.serverOverviewElementChartWrapper}>
                      <ResponsiveContainer height={50} width="99%">
                        <AreaChart data={statistics?.chart?.usersChartData || []}>
                          <Area
                            type="natural"
                            dataKey="value"
                            stroke={'#ffa500'}
                            fill={'#ffe8bd'}
                            strokeWidth={2}
                            fillOpacity="0.25"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div className={classes.serverOverviewElement}>
                    <Typography
                      color="text"
                      colorBrightness="secondary"
                      className={classes.serverOverviewElementText}
                      noWrap
                    >
                      <Typography
                        color="text"
                        colorBrightness="secondary">
                        {t('statisticsActiveDevices')}
                      </Typography>
                      {statistics.activeDevices || 0}

                    </Typography>
                    <div className={classes.serverOverviewElementChartWrapper}>
                      <ResponsiveContainer height={50} width="99%">
                        <AreaChart data={statistics?.chart?.devicesChartData || []}>
                          <Area
                            type="natural"
                            dataKey="value"
                            stroke={'#333366'}
                            fill={'#3333aa'}
                            strokeWidth={2}
                            fillOpacity="0.25"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div className={classes.serverOverviewElement}>
                    <Typography
                      color="text"
                      colorBrightness="secondary"
                      className={classes.serverOverviewElementText}
                      noWrap
                    >
                      <Typography
                        color="text"
                        colorBrightness="secondary">
                        {t('statisticsMessagesReceived')}
                      </Typography>
                      {statistics.messagesReceived || 0}
                    </Typography>
                    <div className={classes.serverOverviewElementChartWrapper}>
                      <ResponsiveContainer height={50} width="99%">
                        <AreaChart data={statistics?.chart?.messagesChartData || []}>
                          <Area
                            type="natural"
                            dataKey="value"
                            stroke={'#4CAF50'}
                            fill={'lime'}
                            strokeWidth={2}
                            fillOpacity="0.25"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </Widget>
              </Grid>


              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Widget title="Comunicação" upperTitle className={classes.card}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <ResponsiveContainer width="100%" height={144}>
                        <PieChart style={{ marginLeft: -20 }}>
                          <Pie
                            data={deviceStatusChartData}
                            innerRadius={30}
                            outerRadius={40}
                            dataKey="value"
                          >
                            {deviceStatusChartData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={theme.palette[entry.color].main}
                              />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Grid>

                    <Grid item xs={6}>
                      <div className={classes.pieChartLegendWrapper}>
                        {deviceStatusChartData.map(({ name, value, color }, index) => (
                          <div key={color} className={classes.legendItemContainer}>
                            <Dot color={color} />
                            <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                              &nbsp;{name}&nbsp;
                      </Typography>
                            <Typography color="text" colorBrightness="secondary">
                              &nbsp;{value}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    </Grid>
                    
                  </Grid>
                </Widget>
              </Grid>
            </Grid>
          </>
          }
             



            <Grid item xs={12}>
              <br />
              <Widget
                title={
                  <Typography variant="h6" color="textSecondary" noWrap style={{ flex: 1 }}>Veículo(s)</Typography>
                } upperTitle
                disableWidgetMenu
                bodyClass={classes.tableWidget}>

                {/** pagination */}
                <TableContainer>
                  {/** pagination */}
                  <TextField
                    margin="normal"
                    value={searchField || ''}
                    onChange={(event) => setSearch(event.target.value)}
                    label={`${t('sharedSearch')}...`}
                    variant="filled"
                    fullWidth
                  />

      <Button onClick={() => getData()} variant="outlined" style={{ width: '20%', marginLeft: '80%', background: '#000', color: '#FFF'}}>Atualizar dados</Button>


                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('sharedName')}</TableCell>
                        <TableCell>{t('sharedDevicePlate')}</TableCell>
                        <TableCell>{t('deviceModel')}</TableCell>
                        <TableCell>{t('deviceLastUpdate')}</TableCell>

                        <TableCell>Ignição</TableCell>

                        <TableCell>{t('positionBlocked')}</TableCell>
                        <TableCell>{t('positionMotion')}</TableCell>
                        <TableCell>{t('eventAlarm')}</TableCell>
                        <TableCell>{t('deviceStatus')}</TableCell>                        
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {/** pagination */}
                      {devicesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (

                      <TableRow key={item.id}>
                          <TableCell className="pl-3 fw-normal">{item.name}</TableCell>
                          <TableCell>{item.contact}</TableCell>
                          <TableCell>{item.model}</TableCell>
                          <TableCell>{item.lastUpdate !== 'Invalid date' ? item.lastUpdate : 'Indisponivel'}</TableCell>
                          <TableCell>
                            <Chip label={item.ignition ? 'Ligada' : 'Desligada'} />
                          </TableCell>
                          <TableCell>
                            <Chip label={item.blocked ? t('sharedYes') : t('sharedNo')} classes={{ root: classes[conditions[item.blocked]] }} />
                          </TableCell>
                          <TableCell>
                            <Chip label={item.motion ? t('sharedYes') : t('sharedNo')} classes={{ root: classes[conditions[item.motion]] }} />
                          </TableCell>
                          <TableCell>
                            <Chip label={item.alarm !== '' ? item.alarm : t('sharedNo')} classes={{ root: classes[conditions[item.alarm !== '']] }} />
                          </TableCell>
                          <TableCell>
                            <Chip label={getStatusText(item.status || 'unknown', t)} classes={{ root: classes[states[item.status.toLowerCase()]] }} />
                          </TableCell>
                         
                     
                         


                          
                        </TableRow>
                      ))}
                      {/** pagination */}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 72 * emptyRows }}>
                          {/*<TableCell align="center" colSpan={12}>{t('sharedRowsEmpty')}</TableCell>*/}
                          <TableCell align="center" colSpan={12} />
                        </TableRow>
                      )}
                      {loading && (
                        <TableRow style={{ height: 72 * emptyRows }}>
                          <TableCell align="center" colSpan={12}>{t('sharedLoading')}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/** pagination */}
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: t('sharedAll'), value: -1 }]}
                  component="div"
                  count={devicesList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t('sharedRowsPerPage')}
                />
              </Widget>
            </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
        </TabPanel>
      </Paper>
    </OptionsLayout >
  );
};




const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
}));


export default DashboardPage;
