import React from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import MainPage from './MainPage';
import DashboardPage from './admin/DashboardPage';
import ServerPage from './admin/ServerPage';
import DevicePage from './DevicePage';
import SocketController from './SocketController';
import NotificationsPage from './settings/NotificationsPage';
import NotificationPage from './settings/NotificationPage';
import PositionPage from './PositionPage';

import ReplayPage from './reports/replay/ReplayPage';
import RouteReportPage from './reports/RouteReportPage';
import EventReportPage from './reports/EventReportPage';
import TripReportPage from './reports/TripReportPage';
import StopReportPage from './reports/StopReportPage';
import SummaryReportPage from './reports/SummaryReportPage';
import ChartReportPage from './reports/ChartReportPage';


import DriversPage from './settings/DriversPage';
import DriverPage from './settings/DriverPage';
import CalendarsPage from './settings/CalendarsPage';
import CalendarPage from './settings/CalendarPage';
import ComputedAttributesPage from './settings/ComputedAttributesPage';
import ComputedAttributePage from './settings/ComputedAttributePage';
import MaintenancesPage from './settings/MaintenancesPage';
import MaintenancePage from './settings/MaintenancePage';
import StatisticsPage from './admin/StatisticsPage';
import CachingController from './CachingController';

import LoginForm from './components/registration/LoginForm';
import RegisterForm from './components/registration/RegisterForm';
import ResetPasswordForm from './components/registration/ResetPasswordForm';

import overrides from './theme/overrides';
import dimensions from './theme/dimensions';

import GeofencesPage from './GeofencesPage';
import GeofencePage from './GeofencePage';

import DevicesPage from './DevicesPage';
import Error404Page from './Error404Page';
import Error504Page from './Error504Page';
import DeviceAccumulatorsPage from './DeviceAccumulatorsPage';

import { LocalizationProvider } from './LocalizationProvider';
import { useEffectAsync } from './reactHelper';

const traccarWhite = '#FFF';
const traccarPurple = '#333366';
const traccarGreen = '#4CAF50';
const traccarRed = '#CC2222';
const traccarGray = '#888888';

const App = () => {

  const initialized = useSelector((state) => !!state.session.server && !!state.session.user);
  const [primaryColor, setPrimaryColor] = React.useState(traccarPurple);
  const [secondaryColor, setSecondaryColor] = React.useState(traccarGreen);
  const [terciaryColor, setTerciaryColor] = React.useState(traccarRed);
  const [quartenaryColor, setQuartenaryColor] = React.useState(traccarWhite);
  const [quintenaryColor, setQuintenaryColor] = React.useState(traccarGray);

  const theme = createTheme({
    palette: {
      common: {
        purple: traccarPurple,
        green: traccarGreen,
        red: traccarRed,
        gray: traccarGray,
      },
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
        contrastText: quartenaryColor,
      },
      terciary: {
        main: terciaryColor,
      },
      quartenary: {
        main: quartenaryColor,
      },
      quintenary: {
        main: quintenaryColor,
      },
      colors: {
        red: {
          color: terciaryColor,
        },
        green: {
          color: secondaryColor,
        },
        gray: {
          color: quintenaryColor,
        },
      },
    },
    overrides,
    dimensions,
  });

  useEffectAsync(async () => {    

    const response = await fetch('/api/revendas');
    if (response.ok) {
      const  regexColor=/^#([0-9a-f]{3}){1,2}$/i;
      const revendas = await response.json();
      var domain = window.location.hostname;
      var index = revendas.findIndex(r => r.url.includes(domain) === true);
      const currentRevenda = index > -1 ? revendas[index] : revendas[0];
      window.localStorage.setItem('revenda', JSON.stringify(currentRevenda));
      setPrimaryColor(regexColor.test(currentRevenda.color1)?currentRevenda.color1:traccarPurple);
      setSecondaryColor(regexColor.test(currentRevenda.color2)?currentRevenda.color2:traccarGreen);
      setTerciaryColor(regexColor.test(currentRevenda.color3)?currentRevenda.color3:traccarRed);
      setQuartenaryColor(regexColor.test(currentRevenda.color4)?currentRevenda.color4:traccarWhite);
      setQuintenaryColor(regexColor.test(currentRevenda.color5)?currentRevenda.color5:setQuintenaryColor);
    }
  }, []);
  
  return (
    <LocalizationProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SocketController />
        <CachingController />
        <Switch>
          <Route exact path="/login" component={LoginForm} />
          <Route exact path="/register" component={RegisterForm} />
          <Route exact path="/reset-password" component={ResetPasswordForm} />
          <Route exact path="/server-down" component={Error504Page} />
          <Route>
            {!initialized ? (<LinearProgress />) : (
              <Switch>
                <Route exact path="/" component={MainPage} />
                <Route exact path="/replay" component={ReplayPage} />
                <Route exact path="/position/:id?" component={PositionPage} />
                <Route exact path="/device/:id?" component={DevicePage} />
                <Route exact path="/geofence/:id?" component={GeofencePage} />
                <Route exact path="/geofences" component={GeofencesPage} />
                <Route exact path="/settings/notifications" component={NotificationsPage} />
                <Route exact path="/settings/notification/:id?" component={NotificationPage} />
                <Route exact path="/settings/drivers" component={DriversPage} />
                <Route exact path="/settings/driver/:id?" component={DriverPage} />
                <Route exact path="/settings/calendars" component={CalendarsPage} />
                <Route exact path="/settings/calendar/:id?" component={CalendarPage} />
                <Route exact path="/settings/attributes" component={ComputedAttributesPage} />
                <Route exact path="/settings/attribute/:id?" component={ComputedAttributePage} />
                <Route exact path="/settings/maintenances" component={MaintenancesPage} />
                <Route exact path="/settings/maintenance/:id?" component={MaintenancePage} />
                <Route exact path="/settings/accumulators/:id?" component={DeviceAccumulatorsPage} />
                
                <Route exact path="/admin/server" component={ServerPage} />
                <Route exact path="/admin/devices" component={DevicesPage} />
                <Route exact path="/admin/statistics" component={StatisticsPage} />
                
                <Route exact path="/reports/route" component={RouteReportPage} />
                <Route exact path="/reports/event" component={EventReportPage} />
                <Route exact path="/reports/trip" component={TripReportPage} />
                <Route exact path="/reports/stop" component={StopReportPage} />
                <Route exact path="/reports/summary" component={SummaryReportPage} />
                <Route exact path="/reports/chart" component={ChartReportPage} />
              
                {/** MOD */}
                <Route exact path="/dashboard" component={DashboardPage} />
                <Route exact path="/server-down" component={Error504Page} />
                <Route exact path='*' component={Error404Page} />
              </Switch>
            )}
          </Route>
        </Switch>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
