import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Paper, Toolbar, TextField, IconButton, Button, Grid, Typography, Box, Divider, Link } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ListIcon from '@material-ui/icons/ViewList';

import { useEffectAsync } from './reactHelper';
import DevicesList from './DevicesList';
import Map from './map/Map';
import SelectedDeviceMap from './map/SelectedDeviceMap';
import AccuracyMap from './map/AccuracyMap';
import GeofenceMap from './map/GeofenceMap';
import CurrentPositionsMap from './map/CurrentPositionsMap';
import CurrentLocationMap from './map/CurrentLocationMap';
import BottomMenu from './components/BottomMenu';
import { useTranslation } from './LocalizationProvider';
import PoiMap from './map/PoiMap';
import MapPadding from './map/MapPadding';
import { sessionActions, invoicesActions } from './store';
import { getIsAdmin, getIsManager, getCurrentUser, getInvoices } from './common/selectors';
import MyAvatar from './components/MyAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    top: 0,
    margin: theme.spacing(1.2),
    width: theme.dimensions.drawerWidthDesktop,
    bottom: 26,
    zIndex: 1301,
    transition: 'transform .5s ease',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  sidebarCollapsed: {
    transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(-100vw)',
    },
  },
  paper: {
    zIndex: 1,
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0, 0),
    '& > *': {
      margin: theme.spacing(0, 0),
    },
  },
  deviceList: {
    flex: 1,
  },
  sidebarToggle: {
    position: 'absolute',
    left: theme.spacing(1.5),
    top: theme.spacing(3),
    borderRadius: '0px',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  sidebarToggleBg: {
    backgroundColor: 'white',
    color: '#777777',
    '&:hover': {
      backgroundColor: '#fefefe',
    },
  },
}));

const MainPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();

  const t = useTranslation();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'));

  const [isDashboard, setDashboard] = useState(false);

  const isAdmin = useSelector(getIsAdmin);
  const isManager = useSelector(getIsManager);
  const currentUser = useSelector(getCurrentUser);
  const invoicesUser = useSelector(getInvoices);

  const [searchKeyword, setSearchKeyword] = useState('');

  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhoto, setUserPhoto] = useState(`./photos/users/default.png`);
  const [userEmail, setUserEmail] = useState('');

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setUserId(currentUser.id);
    setUserName(currentUser.name);
    setUserPhoto(getPhoto(currentUser));
    setUserEmail(currentUser.email);
    setDashboard(currentUser.attributes.enableDashboard);
  }, [currentUser])

  const handleClose = () => {
    setCollapsed(!collapsed);
  };

  const getPhoto = (item) => {
    let file_photo = 'default.png';
    if (item.photo) {
      if (item.photo.length > 0 && item.photo.length < 30) {
        file_photo = item.photo;
      }
    }
    return `./photos/users/${file_photo}`;
  }

  const checkInvoicesOverdue = () => {
    return invoicesUser.filter(function (element) {
      return element.status === 'A' && moment().isAfter(moment(element.duedate).add(1, 'days')) === true;
    }).length
  }

  useEffect(() => setCollapsed(isTablet), [isTablet]);

  useEffectAsync(async () => {
    const response = await fetch('/api/checkinvoices');
    if (response.ok) {
      dispatch(invoicesActions.update(await response.json()));
    }
  }, [currentUser]);

  return (
    <div className={classes.root}>
      <Map>
        {!isTablet && <MapPadding left={parseInt(theme.dimensions.drawerWidthDesktop, 10)} />}
        <CurrentLocationMap />
        <GeofenceMap />
        <AccuracyMap />
        <PoiMap />
        <CurrentPositionsMap />
        <SelectedDeviceMap />
        <BottomMenu />
      </Map>
      <Button
        variant="contained"
        color={isPhone ? 'secondary' : 'primary'}
        classes={{ containedPrimary: classes.sidebarToggleBg }}
        className={classes.sidebarToggle}
        onClick={handleClose}
        disableElevation
        style={{ borderRadius: 10 }}
      >
        <ListIcon />
        <div className={classes.sidebarToggleText}>{t('sharedMenu')}</div>
      </Button>
      <Paper square elevation={3} className={`${classes.sidebar} ${collapsed && classes.sidebarCollapsed}`} style={{ borderRadius: 10 }}>
        <Paper className={classes.paper} square elevation={0} style={{ borderRadius: 10 }}>
          <Grid container spacing={1} style={{ cursor: 'pointer' }}>
            <Grid item xs={2} sm={2} >
              <MyAvatar onClick={() => history.push(`/user/${userId}`)}
                style={{ marginTop: 10, marginLeft: 20 }}
                sx={{
                  mx: 'auto',
                  borderWidth: 2,
                  textAlign: { md: 'center' },
                  borderStyle: 'solid',
                  borderColor: 'common.white',
                  width: { xs: 80, md: 128 },
                  height: { xs: 80, md: 128 }
                }}
                src={userPhoto}
                name={userName}
              />
              <p style={{ marginLeft: 25 }}>
                <Link onClick={async () => {
                  const response = await fetch('/api/session', { method: 'DELETE' });
                  if (response.ok) {
                    (async () => {
                      window.localStorage.setItem('password', '');
                      dispatch(sessionActions.updateUser(null));
                      window.location.reload();
                      history.push('/login');
                    })();
                  }
                }} underline="none">
                  {t('loginLogout')}
                </Link>
              </p>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box onClick={() => history.push(`/user/${userId}`)}
                sx={{
                  ml: { md: 3 },
                  mt: { xs: 1, md: 0 },
                  color: 'common.white',
                  textAlign: { md: 'left' }
                }}
              >
                <Typography variant="body1" >{userName}</Typography>
                <Typography variant="caption" sx={{ opacity: 0.72 }}>{isAdmin ? t('userAdmin') : isManager ? t('sharedRevenda') : userEmail}</Typography>

              </Box>

            </Grid>
            {checkInvoicesOverdue() > 0 &&
              <Typography variant="caption" sx={{ opacity: 0.72 }} style={{ marginLeft: 20, marginBottom: 5, width: '95%', textAlign: 'center' }} >
                <Link onClick={async () => {
                  history.push('/checkinvoices');
                }} style={{ color: 'red' }} underline="none">
                  {t('sharedUserAsInvoicesOverdue').format(checkInvoicesOverdue())}
                </Link>
              </Typography>
            }
          </Grid>
          <Divider style={{ marginTop: 10 }} />
        </Paper>
        <Paper className={classes.paper} square elevation={0}>
          <Toolbar className={classes.toolbar} disableGutters>
            {isTablet && (
              <IconButton onClick={handleClose}>
                <ArrowBackIcon style={{ height: 15, width: 15 }} />
              </IconButton>
            )}
            {!isTablet && (
              <IconButton onClick={handleClose}>
                <ArrowBackIcon style={{ height: 15, width: 15 }} />
              </IconButton>
            )}
            <TextField
              name="searchKeyword"
              value={searchKeyword}
              autoComplete="searchKeyword"
              autoFocus
              style={{ width: '80%' }}
              onChange={(event) => setSearchKeyword(event.target.value)}
              placeholder={t('sharedSearchDevices')}
              variant="filled"
            />

            {isDashboard && (
            <IconButton onClick={() => history.push('/dashboard')}>
              <MenuIcon />
            </IconButton>
            )}
          </Toolbar>
        </Paper>
        <div className={classes.deviceList}>
          <DevicesList filter={searchKeyword} />
        </div>
      </Paper>
    </div>
  );
};

export default MainPage;
