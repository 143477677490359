import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, makeStyles, Typography, ListItem, ListItemText, ListItemSecondaryAction, List, Container, Paper, Divider, Button} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useEffectAsync } from './reactHelper';
import { formatPosition } from './common/formatter';
import { prefixString } from './common/stringUtils';
import { useTranslation } from './LocalizationProvider';
import HeaderBreadcrumbs from './components/HeaderBreadcrumbs';
import Label from './components/Label';
import ReactStreetview from 'react-streetview';
import { getIsAdmin, getIsManager } from './common/selectors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  avatar: {
    zIndex: 0,
    width: '40%',
    height: 'auto',
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 8
  },
}));

const PositionPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();
  const isAdmin = useSelector(getIsAdmin);
  const isManager = useSelector(getIsManager);

  const { id } = useParams();

  const [item, setItem] = useState();
  const [device, setDevice] = useState();

  const revenda = JSON.parse(window.localStorage.getItem('revenda'));


  useEffectAsync(async () => {
    if (id) {
      const response = await fetch(`/api/positions?id=${id}`, {
        headers: {
          Accept: 'application/json',
        },
      });
      if (response.ok) {
        const items = await response.json();
        setItem(items[0]);
      }
    } else {
      setItem({});
    }
  }, [id]);

  useEffectAsync(async () => {
    if (item) {
      const response = await fetch(`/api/devices/${item.deviceId}`, {
        headers: {
          Accept: 'application/json',
        },
      });
      if (response.ok) {
        setDevice(await response.json());
      }
    } else {
      setDevice({});
    }
  }, [item]);

  const formatKey = (key) => t(prefixString('position', key)) || `${t('sharedAttribute')} "${key}"`;

  const attributesList = () => {
    const combinedList = { ...item, ...item.attributes };
    return Object.entries(combinedList).filter(([, value]) => typeof value !== 'object');
  };

  const getPhoto = (item) => {
    let file_photo = item.category === '' || item.category === undefined || !item.category ? '/images/icon/default.png' : `/images/icon/${item.category}.png`;

    if (item.photo) {
      if (item.photo.preview) {
        return item.photo;
      } else {
        if (item.photo.length > 0 && item.photo.length < 30) {
          file_photo = `/photos/devices/${item.photo}`;
        }
      }
    }
    return `.${file_photo}`;
  }

  return (
    <>
      
      <Container>
        <br />
        <HeaderBreadcrumbs
         heading={'Mais Detalhes'}
         links={[ { name: 'Veja todas as iformações do seu veículos' }]}
        />       
      </Container>

      <Container maxWidth="lg" className={classes.root}>
        <Paper>
          {item && device
            && (
              <Grid container spacing={3}>
                    <Button variant="contained" color="primary" style={{ position: 'absolute', right: '10%' }} onClick={() => history.push('/')}>Fechar detalhes</Button>

                <Grid item xs={12} sm={4}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Label
                    color={device.disabled === true ? 'error' : 'success'}
                    style={{ textTransform: 'uppercase', marginTop: 24, marginleft: 54 }}
                  >
                    {device.disabled ? t('sharedDisabled') : t('sharedEnabled')}
                  </Label>
                  <Box
                    sx={{
                      ml: { md: 3 },
                      mt: { xs: 1, md: 0 },
                      color: 'common.white',
                      textAlign: { xs: 'center', md: 'left' }
                    }}
                  >
                    <Typography variant="h5">{device.name}</Typography>
                    <Typography >{device.contact}</Typography>

                    <center>
                    <img src={getPhoto(device)} className={classes.avatar}/>
                    </center>

                  </Box>
                  {revenda.googlekey &&
                    <div style={{
                      width: '95%',
                      height: '210px',
                      marginLeft: 25,
                      marginRight: 25,
                      marginTop: 10,
                      backgroundColor: '#eeeeee'
                    }}>
                      <ReactStreetview
                        apiKey={revenda.googlekey}
                        streetViewPanoramaOptions={{
                          position: { lat: item.latitude, lng: item.longitude },
                          pov: { heading: item.course, pitch: 0 },
                          zoom: 1,
                        }}
                      />
                    </div>
                  }
                  <p style={{ marginLeft: 20 }}>
                    <Typography style={{ fontSize: 18, fontWeight: 'initial' }}>{t('deviceMarch')}</Typography>
                    <Typography variant="body2">{device.march || '-'}</Typography>
                    <Typography style={{ fontSize: 18, fontWeight: 'initial' }}>{t('deviceColor')}</Typography>
                    <Typography variant="body2">{device.color || '-'}</Typography>
                    <Typography style={{ fontSize: 18, fontWeight: 'initial' }}>{t('deviceModel')}</Typography>
                    <Typography variant="body2">{device.model || '-'}</Typography>
                    <Typography style={{ fontSize: 18, fontWeight: 'initial' }}>{t('deviceCategory')}</Typography>
                    <Typography variant="body2">{t(`category${(device.category || 'default').replace(/^\w/, (c) => c.toUpperCase())}`)}</Typography>
                    <Typography style={{ fontSize: 18, fontWeight: 'initial' }}>{t('deviceFuelconsumption')}</Typography>
                    <Typography variant="body2">{device.fuelconsumption || '-'}</Typography>
                    {(isAdmin || isManager) &&
                      <>
                        <Typography style={{ fontSize: 18, fontWeight: 'initial' }}>{t('deviceIdentifier')}</Typography>
                        <Typography variant="body2">{device.uniqueId}</Typography>
                        <Typography style={{ fontSize: 18, fontWeight: 'initial' }}>{t('sharedEquipmentModel')}</Typography>
                        <Typography variant="body2">{device.equipmodel || '-'}</Typography>
                        <Typography style={{ fontSize: 18, fontWeight: 'initial' }}>{t('sharedPhone')}</Typography>
                        <Typography variant="body2">{device.phone || '-'}</Typography>
                        <Typography style={{ fontSize: 18, fontWeight: 'initial' }}>{t('sharedIMEIChip')}</Typography>
                        <Typography variant="body2">{device.icc || '-'}</Typography>
                        <Typography style={{ fontSize: 18, fontWeight: 'initial' }}>{t('positionOperator')}</Typography>
                        <Typography variant="body2">{device.operator || '-'}</Typography>
                        <Typography style={{ fontSize: 18, fontWeight: 'initial' }}>{t('sharedVendorChip')}</Typography>
                        <Typography variant="body2">{device.vendor || '-'}</Typography>
                      </>
                    }
                  </p>
                </Grid>

                <Grid item xs={12} sm={8}>

                  <Typography variant="h5" gutterBottom>
                    {t('sharedTelemetry')}
                  </Typography>
                  <List>
                    {attributesList().map(([key, value], index, list) => (
                      <Fragment key={key}>
                        <ListItem>
                          <ListItemText
                            primary={formatKey(key)}
                          />
                          <ListItemSecondaryAction>
                            <Typography variant="body2">
                              {formatPosition(value, key, t)}
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItem>
                        {index < list.length - 1 ? <Divider /> : null}
                      </Fragment>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}
        </Paper>
      </Container>
    </>
  );
};

export default PositionPage;
