import React, { useState } from 'react';
import {
  Container, TableContainer, Table, TextField, TablePagination, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSelector } from 'react-redux';
import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import OptionsLayout from './OptionsLayout';
import { useTranslation } from '../LocalizationProvider';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { getIsAdmin, getIsManager } from '../common/selectors';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
}));

const ComputedAttributeView = ({ updateTimestamp, onMenuClick }) => {
  const classes = useStyles();
  const t = useTranslation();

  /** pagination */
  const [items, setItems] = useState([]);
  const [itemsAll, setItemsAll] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchField, setSearchField] = useState('');

  const isAdmin = useSelector(getIsAdmin);
  const isManager = useSelector(getIsManager);
  
  useEffectAsync(async () => {
    const response = await fetch('/api/attributes/computed');
    if (response.ok) {
      /** pagination */
      const i = await response.json();
      setItems(i);
      setItemsAll(i);
      setLoading(false);
    }
  }, [updateTimestamp]);


  /** pagination */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);

  const setSearch = (value) => {
    setSearchField(value);
    setItems(itemsAll.filter(x =>
      x.description.toUpperCase().includes(value.toUpperCase()) ||
      x.attribute.toUpperCase().includes(value.toUpperCase()) ||
      x.expression.toUpperCase().includes(value.toUpperCase())
    ));
  }

  return (
    <>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
         heading={'Atributos Cálculados'}
         links={[ { name: 'Visualize atributos criados anteriormente' }]}
        />
      </Container>
      <TableContainer>
        {/** pagination */}
        <TextField
          margin="normal"
          value={searchField || ''}
          onChange={(event) => setSearch(event.target.value)}
          label={`${t('sharedSearch')}...`}
          variant="filled"
          fullWidth
        />
        <Table>
          <TableHead>
            <TableRow>
              {(isAdmin || isManager) && <TableCell className={classes.columnAction} />}
              <TableCell>{t('sharedDescription')}</TableCell>
              <TableCell>{t('sharedAttribute')}</TableCell>
              <TableCell>{t('sharedExpression')}</TableCell>
              <TableCell>{t('sharedType')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/** pagination */}
            {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
              <TableRow key={item.id}>
                {(isAdmin || isManager)
                  && (
                    <TableCell className={classes.columnAction} padding="none">
                      <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.id)}>
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  )}
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.attribute}</TableCell>
                <TableCell>{item.expression}</TableCell>
                <TableCell>{item.type}</TableCell>
              </TableRow>
            ))}
            {/** pagination */}
            {emptyRows > 0 && (
              <TableRow style={{ height: 72 * emptyRows }}>
                {/*<TableCell align="center" colSpan={12}>{t('sharedRowsEmpty')}</TableCell>*/}
                <TableCell align="center" colSpan={12} />
              </TableRow>
            )}
            {loading && (
              <TableRow style={{ height: 72 * emptyRows }}>
                <TableCell align="center" colSpan={12}>{t('sharedLoading')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/** pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: t('sharedAll'), value: -1 }]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('sharedRowsPerPage')}
      />
    </>
  );
};

const ComputedAttributesPage = () => (
  <OptionsLayout>
    <EditCollectionView content={ComputedAttributeView} editPath="/settings/attribute" endpoint="attributes/computed" />
  </OptionsLayout>
);

export default ComputedAttributesPage;
