import React, { useState } from 'react';
import { TextField, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useTheme } from '@material-ui/core/styles';
import { formatDistance, formatHours, formatDate, formatVolume, formatNumber } from '../common/formatter';
import ReportFilter from './ReportFilter';
import ReportLayout from './ReportLayout';
import { useAttributePreference } from '../common/preferences';
import { useTranslation } from '../LocalizationProvider';
import { localizedTextsMap } from '../components/Grid/Grid';

const Filter = ({ setItems }) => {
  const t = useTranslation();
  const [cost, setCost] = useState(0);
  const [daily, setDaily] = useState(false);

  const handleSubmit = async (deviceId, from, to, mail, headers) => {
    const query = new URLSearchParams({
      deviceId, from, to, daily, mail, cost,
    });
    const response = await fetch(`/api/reports/stops?${query.toString()}`, { headers });
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (contentType === 'application/json') {
          
          let data = await response.json();

          let index = 0;
          for (let key in data) {
            data[key].id = index++;

            data[key].engineHoursOff = data[key].duration - data[key].engineHours;
          }
          
          setItems(data);
          
        } else {
          window.location.assign(window.URL.createObjectURL(await response.blob()));
        }
      }
    }
  };

  return <ReportFilter handleSubmit={handleSubmit}>
    <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          margin="normal"
          value={cost || 0}
          type="number"
          inputProps={{
            maxLength: 13,
            step: "0.1"
          }}
          onChange={(event) => setCost(event.target.value)}
          label={t('reportPriceFuel')}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12} sm={2}><br />
        <FormControlLabel
          control={<Checkbox checked={daily} onChange={(e) => setDaily(e.target.checked)} />}
          label={t('reportDaily')}
        />
      </Grid>
  </ReportFilter>;
};

const StopReportPage = () => {
  const theme = useTheme();
  const t = useTranslation();

  const distanceUnit = useAttributePreference('distanceUnit');
  const volumeUnit = useAttributePreference('volumeUnit');

  const [items, setItems] = useState([]);

  const columns = [{
    headerName: t('reportStartTime'),
    field: 'startTime',
    type: 'dateTime',
    width: theme.dimensions.columnWidthDate,
    valueFormatter: ({ value }) => formatDate(value),
  }, {
    headerName: t('positionOdometer'),
    field: 'startOdometer',
    type: 'number',
    width: theme.dimensions.columnWidthNumber,
    valueFormatter: ({ value }) => formatDistance(value, distanceUnit, t),
  }, {
    headerName: t('positionAddress'),
    field: 'address',
    type: 'string',
    hide: true,
    width: theme.dimensions.columnWidthString,
  }, {
    headerName: t('reportEndTime'),
    field: 'endTime',
    type: 'dateTime',
    width: theme.dimensions.columnWidthDate,
    valueFormatter: ({ value }) => formatDate(value),
  }, {
    headerName: t('reportDuration'),
    field: 'duration',
    type: 'string',
    width: theme.dimensions.columnWidthString,
    valueFormatter: ({ value }) => formatHours(value),
  }, {
    headerName: t('reportEngineHours'),
    field: 'engineHours',
    type: 'string',
    width: theme.dimensions.columnWidthString,
    valueFormatter: ({ value }) => formatHours(value),
  }, {
    headerName: 'Horas Desligado',
    field: 'engineHoursOff',
    type: 'string',
    width: theme.dimensions.columnWidthString,
    valueFormatter: ({ value }) => formatHours(value),
  }, {
    headerName: t('reportSpentFuel'),
    field: 'spentFuel',
    type: 'number',
    width: theme.dimensions.columnWidthNumber,
    hide: false,
    valueFormatter: ({ value }) => formatVolume(isNaN(value) ? 0.0 : value, volumeUnit, t),
  }, {
    headerName: t('reportCostFuel'),
    field: 'costFuel',
    type: 'number',
    width: theme.dimensions.columnWidthNumber,
    hide: false,
    valueFormatter: ({ value }) => formatNumber(isNaN(value) ? 0.0 : value, 2),
  }];

  const [pageSize, setPageSize] = React.useState(10);

  return (
    <ReportLayout filter={<Filter setItems={setItems} />}>
      <DataGrid
        rows={items}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        pagination
        autoHeight
        localeText={localizedTextsMap}
          componentsProps={{
            pagination: {
              labelRowsPerPage: "Linhas por página"
            }
          }}
      />
    </ReportLayout>
  );
};

export default StopReportPage;
