import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Accordion, AccordionSummary, AccordionDetails, Container, makeStyles, Typography, Grid
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditItemView from './EditItemView';
import EditAttributesView from './attributes/EditAttributesView';
import { useTranslation } from './LocalizationProvider';
import useGeofenceAttributes from './attributes/useGeofenceAttributes';
import HeaderBreadcrumbs from './components/HeaderBreadcrumbs';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const GeofencePage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const geofenceAttributes = useGeofenceAttributes(t);

  const [item, setItem] = useState();
  const [revendaName, setRevendaName] = useState('Sistema');

  const revenda = JSON.parse(window.localStorage.getItem('revenda'));
  useEffect(() => {
    setRevendaName(revenda.title);
  }, [revenda]);

  return (

    <EditItemView endpoint="geofences" item={item} setItem={setItem}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('sharedGeofence')}
          links={[{ name: revendaName, href: '#' }, { name: t('sharedGeofences'), href: '/geofences' }, { name: t('sharedGeofence') }]}
        />
      </Container>
      {item
        && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedRequired')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                    fullWidth
                      margin="normal"
                      value={item.name || ''}
                      onChange={(event) => setItem({ ...item, name: event.target.value })}
                      label={t('sharedName')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                    fullWidth
                      margin="normal"
                      value={item.description || ''}
                      onChange={(event) => setItem({ ...item, description: event.target.value })}
                      label={t('sharedDescription')}
                      variant="filled"
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedAttributes')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <EditAttributesView
                  attributes={item.attributes}
                  setAttributes={(attributes) => setItem({ ...item, attributes })}
                  definitions={geofenceAttributes}
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
    </EditItemView>
  );
};

export default GeofencePage;
