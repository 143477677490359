const traccarWhite = '#FFF';
const traccarPurple = '#333366';
const traccarGreen = '#4CAF50';
const traccarRed = '#CC2222';
const traccarGray = '#888888';

export default {
  common: {
    purple: traccarPurple,
    green: traccarGreen,
    red: traccarRed,
    gray: traccarGray,
  },
  primary: {
    main: traccarPurple,
  },
  secondary: {
    main: traccarGreen,
    contrastText: traccarWhite,
  },
  movement:{
    main: traccarPurple,
    light: '#3333aa',
  },
  colors: {
    red: {
      color: '#4CAF50',
    },
    green: {
      color: '#CC2222',
    },
    gray: {
      color: '#888888',
    },
  },
};
