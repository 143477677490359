import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import GeoFencesIcon from '@material-ui/icons/BlurCircular';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DashboardIcon from '@material-ui/icons/Dashboard';
import StorageIcon from '@material-ui/icons/Storage';
import BuildIcon from '@material-ui/icons/Build';
import MapIcon from '@material-ui/icons/Map';
import CarsIcon from '@material-ui/icons/Commute';
import BarChartIcon from '@material-ui/icons/BarChart';
import TodayIcon from '@material-ui/icons/Today';
import DriverIcon from '@material-ui/icons/AssignmentInd';
import AttributesComponentIcon from '@material-ui/icons/SettingsInputComponent';
import FindInPageIcon from '@material-ui/icons/FindInPage';

import { getIsAdmin, getUserId, getIsManager } from '../../common/selectors';
import { useTranslation } from '../../LocalizationProvider';

const useAdminRoutes = (t) => useMemo(() => [
  { subheader: t('userAdmin') },
  {
    name: t('settingsServer'),
    href: '/admin/server',
    icon: <StorageIcon />,
  },
  {
    name: t('statisticsTitle'),
    href: '/admin/statistics',
    icon: <BarChartIcon />,
  },
], [t]);

const useMainRoutes = (t, userId) => useMemo(() => [
  { subheader: t('sharedMenu') },
  {
    name: t('sharedDashboard'),
    href: `/dashboard`,
    icon: <DashboardIcon />,
  },
  {
    match: 'map',
    name: t('mapTitle'),
    href: `/`,
    icon: <MapIcon />,
  },
  {
    match: 'geofence',
    name: t('sharedGeofences'),
    href: '/geofences',
    icon: <GeoFencesIcon />,
  },
  {
    match: 'notification',
    name: t('sharedNotifications'),
    href: '/settings/notifications',
    icon: <NotificationsIcon />,
  },
  {
    match: 'driver',
    name: t('sharedDrivers'),
    href: '/settings/drivers',
    icon: <DriverIcon />,
  },
  /*{
    match: 'calendar',
    name: t('sharedCalendars'),
    href: '/settings/calendars',
    icon: <TodayIcon />,
  },
  */
  {
    match: 'attribute',
    name: t('sharedComputedAttributes'),
    href: '/settings/attributes',
    icon: <AttributesComponentIcon />,
  },
  {
    match: 'maintenance',
    name: t('sharedMaintenance'),
    href: '/settings/maintenances',
    icon: <BuildIcon />,
  },{
    match: 'report',
    name: 'Histórico',
    href: '/reports/route',
    icon: <FindInPageIcon />,
  },
  {
    name: 'Veículos',
    href: '/admin/devices',
    icon: <CarsIcon />,
  },
], [t, userId]);

export default () => {
  const t = useTranslation();

  const isAdmin = useSelector(getIsAdmin);
  const isManager = useSelector(getIsManager);

  const userId = useSelector(getUserId);

  const mainRoutes = useMainRoutes(t, userId);
  const adminRoutes = useAdminRoutes(t);
 
  

  return useMemo(() => [...mainRoutes, ...(isAdmin ? adminRoutes : []),], [
    mainRoutes, isAdmin, adminRoutes, isManager, 
  ]);
};
