import React, { } from 'react';
import { makeStyles, Paper, Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { formatHours, formatDate, formatDistance } from '../common/formatter';
import { useAttributePreference } from '../common/preferences';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '330px',
    marginTop: -10,
    marginLeft: -10,
  },
  ...theme.palette.colors,
  listItemContainer: {
    maxWidth: '310px',
    height: 24
  },
  listItemHeader: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  listItemValue: {
    fontSize: 12,
  },
  card_header: {
    backgroundColor: '#DCDCDC',
    height: 40,
    borderRadius: 0,
  },
  avatar: {
    backgroundColor: '#DCDCDC',
    padding: 15,
    height: 18,
    width: 18,
  },
}));

const StopView = ({
  stop, translation
}) => {
  const classes = useStyles();
  const distanceUnit = useAttributePreference('distanceUnit');


  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <Grid container direction="column">
          <Grid item>
            <List>
              <ListItem classes={{ container: classes.listItemContainer }}>
                <ListItemText primary={stop.address}
                  classes={{ primary: classes.listItemHeader }} />
              </ListItem>
              <ListItem classes={{ container: classes.listItemContainer }}>
                <ListItemText primary={translation('reportStartTime')}
                  classes={{ primary: classes.listItemHeader }} />
                <ListItemSecondaryAction
                  classes={{ primary: classes.listItemValue }}>
                  {formatDate(stop.startTime)}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem classes={{ container: classes.listItemContainer }}>
                <ListItemText primary={translation('reportEndTime')}
                  classes={{ primary: classes.listItemHeader }} />
                <ListItemSecondaryAction
                  classes={{ primary: classes.listItemValue }}>
                  {formatDate(stop.endTime)}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem classes={{ container: classes.listItemContainer }}>
                <ListItemText primary={translation('reportDuration')}
                  classes={{ primary: classes.listItemHeader }} />
                <ListItemSecondaryAction
                  classes={{ primary: classes.listItemValue }}>
                  {formatHours(stop.duration)}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem classes={{ container: classes.listItemContainer }}>
                <ListItemText primary={translation('reportStartOdometer')}
                  classes={{ primary: classes.listItemHeader }} />
                <ListItemSecondaryAction
                  classes={{ primary: classes.listItemValue }}>
                  {formatDistance(stop.startOdometer, distanceUnit, translation)}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem classes={{ container: classes.listItemContainer }}>
                <ListItemText primary={translation('reportEndOdometer')}
                  classes={{ primary: classes.listItemHeader }} />
                <ListItemSecondaryAction
                  classes={{ primary: classes.listItemValue }}>
                  {formatDistance(stop.endOdometer, distanceUnit, translation)}
                </ListItemSecondaryAction>
              </ListItem>
            {/*  <ListItem classes={{ container: classes.listItemContainer }}>
                <ListItemText primary={translation('positionSpeed')}
                  classes={{ primary: classes.listItemHeader }} />
                <ListItemSecondaryAction
                  classes={{ primary: classes.listItemValue }}>
                  {formatSpeed(position.speed, speedUnit, translation)}
                </ListItemSecondaryAction>
              </ListItem>
              {position.attributes.batteryLevel && (
                <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary={translation('positionBattery')}
                    classes={{ primary: classes.listItemHeader }} />
                  <ListItemSecondaryAction
                    classes={{ primary: classes.listItemValue }}>
                    <span className={classes[getBatteryStatus(position.attributes.batteryLevel)]}>
                      {formatPosition(position.attributes.batteryLevel, 'batteryLevel', translation)}
                    </span>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
              <ListItem classes={{ container: classes.listItemContainer }}>
                <ListItemText primary={translation('positionDistance')}
                  classes={{ primary: classes.listItemHeader }} />
                <ListItemSecondaryAction
                  classes={{ primary: classes.listItemValue }}>
                  {formatDistance(position.attributes.totalDistance, distanceUnit, translation)}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem classes={{ container: classes.listItemContainer }}>
                <ListItemText primary={translation('positionCourse')}
                  classes={{ primary: classes.listItemHeader }} />
                <ListItemSecondaryAction
                  classes={{ primary: classes.listItemValue }}>
                  {courseFormatter(position.course, translation)}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem classes={{ container: classes.listItemContainer }}>
                <ListItemText primary={translation('positionAddress')}
                  classes={{ primary: classes.listItemHeader }} />
                <ListItemSecondaryAction
                  classes={{ primary: classes.listItemValue }}>
                  {position.address}
                </ListItemSecondaryAction>
              </ListItem>*/}
            </List>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default StopView;
