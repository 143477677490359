import React, { useState } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Container, FormControlLabel, Grid, Typography, Checkbox
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation, useTranslationKeys } from '../LocalizationProvider';
import EditItemView from '../EditItemView';
import { prefixString, unprefixString } from '../common/stringUtils';
import SelectField from '../form/SelectField';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const NotificationPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();
  
  const alarms = useTranslationKeys((it) => it.startsWith('alarm')).map((it) => ({
    key: unprefixString('alarm', it),
    name: t(it),
  }));

  return (
    <EditItemView endpoint="notifications" item={item} setItem={setItem}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={'Configuração de Notificações'}
          links={[ { name: 'Configure os tipos de notificações que você deseja receber.' }]}
        />
      </Container>
      {item
        && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">Configurações nescessárias</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <SelectField
                      margin="normal"
                      value={item.type || 'alarm'}
                      emptyValue={null}
                      onChange={(e) => setItem({ ...item, type: e.target.value })}
                      endpoint="/api/notifications/types"
                      keyGetter={(it) => it.type}
                      titleGetter={(it) => t(prefixString('event', it.type))}
                      label={'Selecione um tipo'}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectField
                      multiple
                      margin="normal"
                      value={item.notificators ? item.notificators.split(/[, ]+/) : []}
                      onChange={(e) => setItem({ ...item, notificators: e.target.value.join() })}
                      endpoint="/api/notifications/notificators"
                      keyGetter={(it) => it.type}
                      titleGetter={(it) => t(prefixString('notificator', it.type))}
                      label={'Canais de visualização'}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>

                  {(!item.type || item.type === 'alarm')
                    && (
                      <Grid item xs={12} sm={4}>
                        <SelectField
                          multiple
                          margin="normal"
                          value={item.attributes && item.attributes.alarms ? item.attributes.alarms.split(/[, ]+/) : []}
                          onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, alarms: e.target.value.join() } })}
                          data={alarms}
                          keyGetter={(it) => it.key}
                          label={'Selecione um alarme'}
                          variant="filled"
                          fullWidth
                        />
                      </Grid>
                    )}
                  <Grid item xs={12} sm={12}>

                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={item.always}
                          onChange={(event) => setItem({ ...item, always: event.target.checked })}
                        />
                      )}
                      label={'Aplicar para todos os veículos'}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
    </EditItemView>
  );
};

export default NotificationPage;
