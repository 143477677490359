import React, { createContext, useContext } from 'react';
import usePersistedState from './common/usePersistedState';

import en from './language/en.json';
import ptBR from './language/pt_BR.json';

const languages = {
  en: { data: en, name: 'English' },
  ptBR: { data: ptBR, name: 'Português (Brasil)' },
};


const LocalizationContext = createContext({
  languages,
  language: 'ptBR',
  setLanguage: () => {},
});

export const LocalizationProvider = ({ children }) => {
  const [language, setLanguage] = usePersistedState('language', 'ptBR');

  return (
    <LocalizationContext.Provider value={{ languages, language, setLanguage }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);

export const useTranslation = () => {
  const context = useContext(LocalizationContext);
  const { data } = context.languages[context.language];
  return (key) => data[key];
};

export const useTranslationKeys = (predicate) => {
  const context = useContext(LocalizationContext);
  const { data } = context.languages[context.language];
  return Object.keys(data).filter(predicate);
};
