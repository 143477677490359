import { useMemo } from 'react';

export default (t) => useMemo(() => ({
  raw: {
    name: t('positionRaw'),
    type: 'string',
  }, 
  index: {
    name: t('positionIndex'),
    type: 'number',
  },
  hdop: {
    name: t('positionHdop'),
    type: 'number',
  },
  vdop: {
    name: t('positionPdop'),
    type: 'number',
  },
  sat: {
    name: t('positionSat'),
    type: 'number',
  },
  satVisible: {
    name: t('positionSatVisible'),
    type: 'number',
  },
  rssi: {
    name: t('positionRssi'),
    type: 'number',
  },
  gps: {
    name: t('positionGps'),
    type: 'number',
  },
  roaming: {
    name: t('positionRoaming'),
    type: 'boolean',
  },
  event: {
    name: t('positionEvent'),
    type: 'string',
  },
  alarm: {
    name: t('positionAlarm'),
    type: 'string',
  },
  status: {
    name: t('positionStatus'),
    type: 'string',
  },
  odometer: {
    name: t('positionOdometer'),
    type: 'number',
    dataType: 'distance',
  },
  serviceOdometer: {
    name: t('positionServiceOdometer'),
    type: 'number',
    dataType: 'distance',
  },
  tripOdometer: {
    name: t('positionTripOdometer'),
    type: 'number',
    dataType: 'distance',
  },
  hours: {
    name: t('positionHours'),
    type: 'number',
    dataType: 'hours'
  },
  steps: {
    name: t('positionSteps'),
    type: 'number'
  },
  input: {
    name: t('positionInput'),
    type: 'string'
  },
  output: {
    name: t('positionOutput'),
    type: 'string'
  },
  power: {
    name: t('positionPower'),
    type: 'string',
    dataType: 'voltage'
  },
  battery: {
    name: t('positionBattery'),
    type: 'string',
    dataType: 'voltage'
  },
  batteryLevel: {
    name: t('positionBatteryLevel'),
    type: 'string',
    dataType: 'percentage'
  },
  fuel: {
    name: t('positionFuel'),
    type: 'number',
    dataType: 'volume'
  },
  fuelConsumption: {
    name: t('positionFuelConsumption'),
    type: 'number',
    dataType: 'consumption'
  },
  versionFw: {
    name: t('positionVersionFw'),
    type: 'string',
  },
  versionHw: {
    name: t('positionVersionHw'),
    type: 'string',
  },
  type: {
    name: t('sharedType'),
    type: 'string',
  },
  
  ignition: {
    name: t('positionIgnition'),
    type: 'boolean',
  },
 
}), [t]);

/**
 *  {
        key: 'flags',
        name: Strings.positionFlags,
        valueType: 'string'
    }, {
        key: 'charge',
        name: Strings.positionCharge,
        valueType: 'boolean'
    }, {
        key: 'ip',
        name: Strings.positionIp,
        valueType: 'string'
    }, {
        key: 'archive',
        name: Strings.positionArchive,
        valueType: 'boolean'
    }, {
        key: 'distance',
        name: Strings.positionDistance,
        valueType: 'number',
        dataType: 'distance'
    }, {
        key: 'totalDistance',
        name: Strings.deviceTotalDistance,
        valueType: 'number',
        dataType: 'distance'
    }, {
        key: 'rpm',
        name: Strings.positionRpm,
        valueType: 'number'
    }, {
        key: 'vin',
        name: Strings.positionVin,
        valueType: 'string'
    }, {
        key: 'approximate',
        name: Strings.positionApproximate,
        valueType: 'boolean'
    }, {
        key: 'throttle',
        name: Strings.positionThrottle,
        valueType: 'number'
    }, {
        key: 'motion',
        name: Strings.positionMotion,
        valueType: 'boolean'
    }, {
        key: 'armed',
        name: Strings.positionArmed,
        valueType: 'number'
    }, {
        key: 'geofence',
        name: Strings.sharedGeofence,
        valueType: 'string'
    }, {
        key: 'acceleration',
        name: Strings.positionAcceleration,
        valueType: 'number'
    }, {
        key: 'deviceTemp',
        name: Strings.positionDeviceTemp,
        valueType: 'number',
        dataType: 'temperature'
    }, {
        key: 'operator',
        name: Strings.positionOperator,
        valueType: 'string'
    }, {
        key: 'command',
        name: Strings.deviceCommand,
        valueType: 'string'
    }, {
        key: 'blocked',
        name: Strings.positionBlocked,
        valueType: 'boolean'
    }, {
        key: 'dtcs',
        name: Strings.positionDtcs,
        valueType: 'string'
    }, {
        key: 'obdSpeed',
        name: Strings.positionObdSpeed,
        valueType: 'number',
        dataType: 'speed'
    }, {
        key: 'obdOdometer',
        name: Strings.positionObdOdometer,
        valueType: 'number',
        dataType: 'distance'
    }, {
        key: 'result',
        name: Strings.eventCommandResult,
        valueType: 'string'
    }, {
        key: 'driverUniqueId',
        name: Strings.positionDriverUniqueId,
        valueType: 'string',
        dataType: 'driverUniqueId'
    }],
*/