import React, { useState, useCallback } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, makeStyles, Container, FormControlLabel, Checkbox, Typography, TextField, FormControl, InputLabel, MenuItem, Select, Grid
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DropzoneArea } from 'material-ui-dropzone';
import EditItemView from './EditItemView';
import EditAttributesView from './attributes/EditAttributesView';
import SelectField from './form/SelectField';
import deviceCategories from './common/deviceCategories';
import LinkField from './form/LinkField';
import { prefixString } from './common/stringUtils';
import { useTranslation } from './LocalizationProvider';
import useDeviceAttributes from './attributes/useDeviceAttributes';
import { getGroups } from './common/selectors';
import Label from './components/Label';
import { UploadAvatar } from './components/upload';
import { fData } from './utils/formatNumber';
import HeaderBreadcrumbs from './components/HeaderBreadcrumbs';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const DevicePage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const groups = useSelector(getGroups);
  const deviceAttributes = useDeviceAttributes(t);

  const [item, setItem] = useState();

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleDrop = useCallback(
    async (acceptedFiles, user) => {
      const file = acceptedFiles[0];
      if (file) {
        setItem({
          ...user,
          photo: {
            ...file,
            preview: URL.createObjectURL(file)
          },
          photoBase64: await toBase64(file)
        });
      }
    },
    [setItem]
  );

  const getPhoto = (item) => {
    let file_photo = item.category === '' || item.category === undefined || !item.category ? '/images/icon-default.png' : `/images/icon-${item.category}.png`;

    if (item.photo) {
      if (item.photo.preview) {
        return item.photo;
      } else {
        if (item.photo.length > 0 && item.photo.length < 30) {
          file_photo = `/photos/devices/${item.photo}`;
        }
      }
    }
    return `.${file_photo}`;
  }

  const handlePhotoInstall = async (files) => {
    if (files.length > 0) {
      setItem({ ...item, photoInstallBase64: await toBase64(files[0]) });
    } else {
      setItem({ ...item, photoInstallBase64: null });
    }
  };

  return (
    <EditItemView endpoint="devices" item={item} setItem={setItem}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={ 'Veículo' }
          links={[ { name: 'Edite os dados abaixo', href: '/admin/devices' } ]}
        /> 
      </Container>
      {item
        && (
          <>
            <Accordion defaultExpanded style={{ borderRadius: 10, display: 'none' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedRequired')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Label
                      color={item.disabled === true ? 'error' : 'success'}
                      style={{ textTransform: 'uppercase', marginTop: 24, marginLeft: 24 }}
                    >
                      {item.disabled ? t('sharedDisabled') : t('sharedEnabled')}
                    </Label>
                    <Box sx={{ mb: 12 }}>
                      <UploadAvatar
                        accept="image/*"
                        file={getPhoto(item)}
                        maxSize={3145728}
                        onDrop={(acceptedFiles) => handleDrop(acceptedFiles, item)}
                        caption={''}
                      />
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            style={{
                              mt: 12,
                              mx: 'auto',
                              display: 'block',
                              textAlign: 'center',
                              color: 'text.secondary'
                            }}
                          >
                            {t('sharedAllowed')}  *.jpeg, *.jpg, *.png, *.gif
                                <br /> {t('sharedMaxSize')} {fData(3145728)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Grid item xs={12}>
                      <FormControl variant="filled" margin="normal" fullWidth>
                        <InputLabel>{t('groupDialog')}</InputLabel>
                        <Select
                          value={item.groupId || 0}
                          onChange={(event) => setItem({ ...item, groupId: event.target.value })}
                        >
                          <MenuItem key={0} value={''}>{t('sharedSelectNone')}</MenuItem>
                          {(groups).map(({ id, name }) => (
                            <MenuItem key={id} value={id}>{name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <TextField
                        fullWidth
                        margin="normal"
                        value={item.name || ''}
                        onChange={(event) => setItem({ ...item, name: event.target.value })}
                        label={t('sharedName')}
                        variant="filled"
                      />
                    </Grid>

                    <Grid item xs={12} sm={8}>
                      <TextField
                        margin="normal"
                        value={item.uniqueId || ''}
                        onChange={(event) => setItem({ ...item, uniqueId: event.target.value })}
                        label={t('deviceIdentifier')}
                        variant="filled"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        margin="normal"
                        value={item.contact || ''}
                        onChange={(event) => setItem({ ...item, contact: event.target.value })}
                        label={t('sharedDevicePlate')}
                        variant="filled"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        margin="normal"
                        value={item.category || 'default'}
                        emptyValue={null}
                        onChange={(event) => setItem({ ...item, category: event.target.value })}
                        data={deviceCategories.map((category) => ({
                          id: category,
                          name: t(`category${category.replace(/^\w/, (c) => c.toUpperCase())}`),
                        }))}
                        label={t('deviceCategory')}
                        variant="filled"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>


            <Accordion style={{ borderRadius: 10, display: 'none'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedExtra')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.equipmodel || ''}
                      onChange={(event) => setItem({ ...item, equipmodel: event.target.value })}
                      label={t('sharedEquipmentModel')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.phone || ''}
                      onChange={(event) => setItem({ ...item, phone: event.target.value })}
                      label={t('sharedPhone')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.icc || ''}
                      onChange={(event) => setItem({ ...item, icc: event.target.value })}
                      label={t('sharedIMEIChip')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.operator || ''}
                      onChange={(event) => setItem({ ...item, operator: event.target.value })}
                      label={t('sharedOperatorChip')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.vendor || ''}
                      onChange={(event) => setItem({ ...item, vendor: event.target.value })}
                      label={t('sharedVendorChip')}
                      variant="filled"
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.march || ''}
                      onChange={(event) => setItem({ ...item, march: event.target.value })}
                      label={t('deviceMarch')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.model || ''}
                      onChange={(event) => setItem({ ...item, model: event.target.value })}
                      label={t('deviceModel')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.color || ''}
                      onChange={(event) => setItem({ ...item, color: event.target.value })}
                      label={t('deviceColor')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.fuelconsumption || ''}
                      onChange={(event) => setItem({ ...item, fuelconsumption: event.target.value })}
                      label={t('deviceFuelconsumption')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControlLabel
                      control={<Checkbox checked={item.disabled} onChange={(event) => setItem({ ...item, disabled: event.target.checked })} />}
                      label={t('sharedDisabled')}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ borderRadius: 10, display: 'none'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedInstallation')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container spacing={3}>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.tech || ''}
                      onChange={(event) => setItem({ ...item, tech: event.target.value })}
                      label={t('sharedTech')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.techphone || ''}
                      onChange={(event) => setItem({ ...item, techphone: event.target.value })}
                      label={t('sharedPhone')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.dateInstall || ''}
                      onChange={(event) => setItem({ ...item, dateInstall: event.target.value })}
                      label={t('sharedDateInstall')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.dateuninstall || ''}
                      onChange={(event) => setItem({ ...item, dateuninstall: event.target.value })}
                      label={t('sharedDateUninstall')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                  <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel>{t('sharedFile')}</InputLabel>
                  <DropzoneArea
                  dropzoneText={t('sharedPhotoInstall')}
                    filesLimit={1}
                    acceptedFiles={[".zip"]}
                    onChange={handlePhotoInstall}
                  />
                </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* 

            <Accordion defaultExpanded style={{ borderRadius: 10 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedAttributes')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <EditAttributesView
                  attributes={item.attributes}
                  setAttributes={(attributes) => setItem({ ...item, attributes })}
                  definitions={deviceAttributes}
                />
              </AccordionDetails>
            </Accordion>

            */}

            {item.id
              && (
                <Accordion defaultExpanded style={{ borderRadius: 10 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">
                      {t('sharedConnections')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <LinkField
                      margin="normal"
                      endpointAll="/api/geofences"
                      endpointLinked={`/api/geofences?deviceId=${item.id}`}
                      baseId={item.id}
                      keyBase="deviceId"
                      keyLink="geofenceId"
                      label={t('sharedGeofences')}
                      variant="filled"
                    />
                    <LinkField
                      margin="normal"
                      endpointAll="/api/notifications"
                      endpointLinked={`/api/notifications?deviceId=${item.id}`}
                      baseId={item.id}
                      keyBase="deviceId"
                      keyLink="notificationId"
                      titleGetter={(it) => t(prefixString('event', it.type))}
                      label={t('sharedNotifications')}
                      variant="filled"
                    />
                    <LinkField
                      margin="normal"
                      endpointAll="/api/drivers"
                      endpointLinked={`/api/drivers?deviceId=${item.id}`}
                      baseId={item.id}
                      keyBase="deviceId"
                      keyLink="driverId"
                      label={t('sharedDrivers')}
                      variant="filled"
                    />
                    <LinkField
                      margin="normal"
                      endpointAll="/api/attributes/computed"
                      endpointLinked={`/api/attributes/computed?deviceId=${item.id}`}
                      baseId={item.id}
                      keyBase="deviceId"
                      keyLink="attributeId"
                      titleGetter={(it) => it.description}
                      label={t('sharedComputedAttributes')}
                      variant="filled"
                    />
                    <LinkField
                      margin="normal"
                      endpointAll="/api/maintenance"
                      endpointLinked={`/api/maintenance?deviceId=${item.id}`}
                      baseId={item.id}
                      keyBase="deviceId"
                      keyLink="maintenanceId"
                      label={t('sharedMaintenance')}
                      variant="filled"
                    />
                  </AccordionDetails>
                </Accordion>
              )}
          </>
        )}
    </EditItemView>
  );
};

export default DevicePage;
