import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Button, FormControl, Container, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory, useParams } from 'react-router-dom';
import OptionsLayout from './settings/OptionsLayout';
import HeaderBreadcrumbs from './components/HeaderBreadcrumbs';
import { notyMessage } from './common/dialogs';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    flexDirection: 'column',
  },
}));

const DeviceAccumulatorsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();

  const { id } = useParams();

  const [item, setItem] = useState();

  const [revendaName, setRevendaName] = useState('Sistema');

  const revenda = JSON.parse(window.localStorage.getItem('revenda'));
  useEffect(() => {
    setRevendaName(revenda.title);
  }, [revenda]);

  useEffect(() => {
    (async () => {
      const response = await fetch(`/api/positions`);
      if (response.ok) {
        const data = await response.json();
        var position = data.filter(function (el) {
          return el.deviceId === parseInt(id);
        });

        setItem({
          totalDistance: position[0] ? position[0].attributes ? position[0].attributes.totalDistance : 0 : 0,
          hours: position[0] ? position[0].attributes ? position[0].attributes.hours : 0 : 0,
        });
      }
    })();
  }, []);

  const handleSave = async () => {
    const response = await fetch(`/api/devices/${id}/accumulators`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...item,
        deviceId: id
      }),
    });

    if (response.ok) {
      await notyMessage('success', t('successTitle'), t('successMessage'), () => {
        history.goBack();
      }, true);
    } else {
      await notyMessage('error', t('errorTitle'), response.statusText, () => { }, true);
    }
  };

  return (
    <OptionsLayout>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('sharedDeviceAccumulators')}
          links={[{ name: revendaName, href: '#' }, { name: t('sharedDeviceAccumulators') }]}
        />
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        {item && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedPreferences')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container spacing={3}>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      type="number"
                      value={item.totalDistance || 0}
                      onChange={(event) => setItem({ ...item, totalDistance: event.target.value })}
                      label={t('deviceTotalDistance')}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      type="number"
                      value={item.hours || 0}
                      onChange={(event) => setItem({ ...item, hours: event.target.value })}
                      label={t('positionHours')}
                      variant="filled"
                    />
                  </Grid>

                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
        <FormControl fullWidth margin="normal">
          <div className={classes.buttons}>
            <Button type="button" color="primary" variant="outlined" onClick={() => history.goBack()}>
              {t('sharedCancel')}
            </Button>
            <Button type="button" color="primary" variant="contained" onClick={handleSave}>
              {t('sharedSave')}
            </Button>
          </div>
        </FormControl>
      </Container>
    </OptionsLayout>
  );
};

export default DeviceAccumulatorsPage;
