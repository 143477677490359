import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';

const CommonPage = ({ children }) => {
  const defaultStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      height: '100vh',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(0, 25, 0, 0),
      },
    },
    form: {
      maxWidth: theme.spacing(80),
      padding: theme.spacing(5),
      width: '100%',
    },
  }));

  const classes = defaultStyles();
  
  return (
    <>
      <main className={classes.root}>
        <Paper className={classes.paper}>
          <form className={classes.form}>
            {children}
          </form>
        </Paper>
      </main>
    </>
  );
};

export default CommonPage;
