import React  from 'react';
// hooks
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {

  return (
    <MAvatar
      src={''}
      alt={other.name || 'Image'}
      color={true ? 'default' : createAvatar(other.name).color}
      {...other}
    >
      {createAvatar(other.name).name}
    </MAvatar>
  );
}
