import React, { useState } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';
import { useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sessionActions } from '../../store';
import { useTranslation } from '../../LocalizationProvider';
import StartPage from '../../StartPage';
import usePersistedState from '../../common/usePersistedState';
import { notyMessage } from '../../common/dialogs';

const LoginForm = () => { 
 
  const dispatch = useDispatch();
  const t = useTranslation();
  const history = useHistory();

  const [failed, setFailed] = useState(false);
  const [email, setEmail] = usePersistedState('loginEmail', '');
  const [password, setPassword] = useState('');
  const [backgroundColor, setColor] = useState('#000');

  window.setTimeout(function(){ 

    let revenda = JSON.parse(window.localStorage.getItem('revenda'));
    setColor(revenda.color1) 
  
  }, 250)


  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch('/api/session', {
      method: 'POST',
      body: new URLSearchParams(`email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`),
    });

    if (response.ok) {
      const user = await response.json();

      dispatch(sessionActions.updateUser({
        ...user,
        password
      }));
      
      window.localStorage.setItem('password', password);

      let profile;

      if(user.administrator == true){
      profile = 'administrador';  
      }

      if(user.administrator == false){
      profile = 'usuario';  
      }
      
      fetch(`${localStorage.getItem('log')}?userid=${user.id}&name=${user.name}&login=${user.login}&email=${user.email}&profile=${profile}&action=Login`);
      
      history.push('/');

    } else {
      switch (response.status) {
        case 401:
          setFailed(true);
          await notyMessage('error', 'Atenção', 'Falha ao realizar o login', () => { }, true);
          break;
        default:
          const statusText = await response.text();
          if (statusText.includes('Account is disabled') > -1) {
            await notyMessage('warning', 'Atenção', 'Sua conta está desabilitada!', () => { }, true);
          } else {
            await notyMessage('error', 'Atenção', statusText, () => { }, true);
          }
          break;
      }
      setPassword('');
    }
  };

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && email && password) {
      handleSubmit(e);
    }
  };


  return (
    
    <StartPage>
      <Grid container direction="column" spacing={2}>        
        <Grid item>
          <TextField
            required
            fullWidth
            error={failed}
            label="Login"
            name="email"
            value={email}
            autoComplete="email"
            autoFocus={!email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyUp={handleSpecialKey}
            helperText={failed && 'Email ou Senha inválidos!'}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            error={failed}
            label="Senha"
            name="password"
            value={password}
            type="password"
            autoComplete="current-password"
            autoFocus={!!email}
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={handleSpecialKey}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <Button
            onClick={handleSubmit}
            onKeyUp={handleSpecialKey}
            variant="contained"
            color="primary"
            style={{backgroundColor}}
            disabled={!email || !password}
            fullWidth
          >
            {t('loginLogin')}
          </Button>
        </Grid>
      </Grid>
    </StartPage>
  );
};


export default LoginForm;
