import React, {} from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Button, FormControl, Container, Checkbox, FormControlLabel, InputLabel, MenuItem, Select, Grid
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from '../store';
import EditAttributesView from '../attributes/EditAttributesView';
import useDeviceAttributes from '../attributes/useDeviceAttributes';
import useServerAttributes from '../attributes/useServerAttributes';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import mapsLayers from '../common/mapsLayers';
import { coordinatesFormat } from '../common/coordinateFormat';
import { notyMessage } from '../common/dialogs';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    flexDirection: 'column',
  },
}));

const ServerPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslation();

  const userAttributes = useServerAttributes(t);
  const deviceAttributes = useDeviceAttributes(t);

  const item = useSelector((state) => state.session.server);
  const setItem = (updatedItem) => dispatch(sessionActions.updateServer(updatedItem));
  
  
  const handleSave = async () => {
    const response = await fetch('/api/server', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    });

    if (response.ok) {
      await notyMessage('success', t('successTitle'), t('successMessage'), () => {
        history.push('/dashboard')
      }, true);
    } else {
      await notyMessage('error', t('errorTitle'), response.statusText, () => {}, true);
    }
  };

  return (
    <OptionsLayout>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
         heading={'Servidor'}
         links={[ { name: 'Gerencie as configurações do servidor' }]}
        />
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        {item && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedPreferences')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="filled" margin="normal" fullWidth>
                      <InputLabel>{t('mapLayer')}</InputLabel>
                      <Select
                        value={item.map || ''}
                        onChange={(event) => setItem({ ...item, map: event.target.value })}
                      >
                        <MenuItem key={0} value={''}>{t('sharedSelectNone')}</MenuItem>
                        {mapsLayers.map(({ id, title }) => (
                          <MenuItem key={id} value={id}>{t(title)}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      value={item.bingKey || ''}
                      onChange={(event) => setItem({ ...item, bingKey: event.target.value })}
                      label={t('mapBingKey')}
                      variant="filled"
                    />
                  </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        margin="normal"
                        value={item.mapUrl || ''}
                        onChange={(event) => setItem({ ...item, mapUrl: event.target.value })}
                        label={t('mapCustomLabel')}
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        margin="normal"
                        value={item.latitude || ''}
                        onChange={(event) => setItem({ ...item, latitude: event.target.value })}
                        label={t('positionLatitude')}
                        variant="filled"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        margin="normal"
                        value={item.longitude || ''}
                        onChange={(event) => setItem({ ...item, longitude: event.target.value })}
                        label={t('positionLongitude')}
                        variant="filled"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        margin="normal"
                        value={item.zoom || ''}
                        onChange={(event) => setItem({ ...item, zoom: event.target.value })}
                        label={t('serverZoom')}
                        variant="filled"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl variant="filled" margin="normal" fullWidth>
                        <InputLabel>{t('settingsCoordinateFormat')}</InputLabel>
                        <Select
                          value={item.coordinateFormat || ''}
                          onChange={(event) => setItem({ ...item, coordinateFormat: event.target.value })}
                        >
                          <MenuItem key={0} value={''}>{t('sharedSelectNone')}</MenuItem>
                          {(coordinatesFormat).map(({ key, value }) => (
                            <MenuItem key={key} value={key}>{t(value)}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl variant="filled" margin="normal" fullWidth>
                        <InputLabel>{t('settingsTwelveHourFormat')}</InputLabel>
                        <Select
                          value={Boolean(item.twelveHourFormat)}
                          onChange={(event) => setItem({ ...item, twelveHourFormat: event.target.value })}
                        >
                          <MenuItem key={1} value={true}>{t('sharedYes')}</MenuItem>
                          <MenuItem key={0} value={false}>{t('sharedNo')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        margin="normal"
                        value={item.poiLayer || ''}
                        onChange={(event) => setItem({ ...item, poiLayer: event.target.value })}
                        label={t('mapPoiLayer')}
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        margin="normal"
                        value={item.announcement || ''}
                        onChange={(event) => setItem({ ...item, announcement: event.target.value })}
                        label={t('serverAnnouncement')}
                        variant="filled"
                      />
                    </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedPermissions')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={<Checkbox checked={item.registration} onChange={(event) => setItem({ ...item, registration: event.target.checked })} />}
                      label={t('serverRegistration')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={<Checkbox checked={item.readonly} onChange={(event) => setItem({ ...item, readonly: event.target.checked })} />}
                      label={t('serverReadonly')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={<Checkbox checked={item.deviceReadonly} onChange={(event) => setItem({ ...item, deviceReadonly: event.target.checked })} />}
                      label={t('userDeviceReadonly')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={<Checkbox checked={item.limitCommands} onChange={(event) => setItem({ ...item, limitCommands: event.target.checked })} />}
                      label={t('userLimitCommands')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={<Checkbox checked={item.forceSettings} onChange={(event) => setItem({ ...item, forceSettings: event.target.checked })} />}
                      label={t('serverForceSettings')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={<Checkbox checked={item.emailEnabled} onChange={(event) => setItem({ ...item, emailEnabled: event.target.checked })} />}
                      label={t('sharedEmailEnabled')}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedAttributes')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <EditAttributesView
                  attributes={item.attributes}
                  setAttributes={(attributes) => setItem({ ...item, attributes })}
                  definitions={{ ...userAttributes, ...deviceAttributes }}
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
        <FormControl fullWidth margin="normal">
          <div className={classes.buttons}>
            <Button type="button" color="primary" variant="outlined" onClick={() => history.goBack()}>
              {t('sharedCancel')}
            </Button>
            <Button type="button" color="primary" variant="contained" onClick={handleSave}>
              {t('sharedSave')}
            </Button>
          </div>
        </FormControl>
      </Container>
    </OptionsLayout>
  );
};

export default ServerPage;
