import React, { useState } from 'react';
import {Paper, FormControlLabel, Checkbox, Grid, Button} from '@material-ui/core';
import { dialogMessage } from '../common/dialogs';
import { DataGrid } from '@material-ui/data-grid';
import { useTheme } from '@material-ui/core/styles';
import { formatDistance, formatSpeed, formatBoolean, formatDate, formatCoordinate } from '../common/formatter';
import ReportFilter from './ReportFilter';
import ReportLayout from './ReportLayout';
import { useAttributePreference, usePreference } from '../common/preferences';
import { localizedTextsMap } from '../components/Grid/Grid';
import { useTranslation } from '../LocalizationProvider';

const Filter = ({ setItems }) => {

  const [addres, setAddres] = useState(false);
  const [daily, setDaily] = useState(false);
  const t = useTranslation();

  const handleSubmit = async (deviceId, from, to, mail, headers) => {
    

    if(deviceId == undefined){

    dialogMessage('info', 'Atenção', 'Nenhum veiculo selecionado!');  
    return false;   
    }

    // REVERSE GEOCODE ADDRESS SET
    if(addres == true){
      
    dialogMessage('info', 'Atenção', 'Seu histórico está sendo gerado com endereços... isto pode demorar vários minutos!'); 
    await fetch(`${localStorage.getItem('reverse')}?inicio=${from}&fim=${to}&device=${deviceId}`);
    }

    const query = new URLSearchParams({
    deviceId, from, to, daily, mail,
    });

    const response = await fetch(`/api/reports/route?${query.toString()}`, { headers });
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (contentType === 'application/json') {

          setItems(await response.json());

        } else {
          window.location.assign(window.URL.createObjectURL(await response.blob()));
        }
      }
    }
  };

  return <ReportFilter handleSubmit={handleSubmit}>
  <Grid item xs={12} sm={12}>
        <FormControlLabel
          control={<Checkbox checked={addres} onChange={(e) => setAddres(e.target.checked)} />}
          label={'Mostar endereços'}
        />

        <FormControlLabel
          control={<Checkbox checked={daily} onChange={(e) => setDaily(e.target.checked)} />}
          label={t('reportDaily')}
        />

      </Grid>
  </ReportFilter> 

};

const RouteReportPage = () => {
  const theme = useTheme();
  const t = useTranslation();

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const coordinateFormat = usePreference('coordinateFormat');

  const columns = [{
    headerName: t('positionFixTime'),
    field: 'fixTime',
    type: 'dateTime',
    width: theme.dimensions.columnWidthDate,
    valueFormatter: ({ value }) => formatDate(value),
  }, {
    headerName: t('positionAddress'),
    field: 'address',
    type: 'string',
    width: theme.dimensions.columnWidthAdress,
  }, {
    headerName: t('positionLatitude'),
    field: 'latitude',
    type: 'number',
    width: theme.dimensions.columnWidthNumber,
    valueFormatter: ({ value }) => formatCoordinate('latitude', value, coordinateFormat),
  }, {
    headerName: t('positionLongitude'),
    field: 'longitude',
    type: 'number',
    width: theme.dimensions.columnWidthNumber,
    valueFormatter: ({ value }) => formatCoordinate('longitude', value, coordinateFormat),
  }, {
    headerName: t('positionSpeed'),
    field: 'speed',
    type: 'number',
    width: theme.dimensions.columnWidthString,
    valueFormatter: ({ value }) => formatSpeed(value, speedUnit, t),
  }, {
    headerName: t('positionIgnition'),
    field: 'ignition',
    type: 'boolean',
    width: theme.dimensions.columnWidthBoolean,
    valueGetter: ({ row }) => row.attributes.ignition,
    valueFormatter: ({ value }) => formatBoolean(value, t),
  }, {
    headerName: t('deviceTotalDistance'),
    field: 'totalDistance',
    type: 'number',
    hide: true,
    width: theme.dimensions.columnWidthNumber,
    valueGetter: ({ row }) => row.attributes.totalDistance,
    valueFormatter: ({ value }) => formatDistance(value, distanceUnit, t),
  }];

  const [items, setItems] = useState([]);
  const [pageSize, setPageSize] = React.useState(10);

  return (
    <ReportLayout filter={<Filter setItems={setItems} />}>
      <Paper>
        <DataGrid
          rows={items}
          columns={columns}
          autoHeight
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50]}
          pagination
          localeText={localizedTextsMap}
          componentsProps={{
            pagination: {
              labelRowsPerPage: "Linhas por página"
            }
          }}
        />
      </Paper>
    </ReportLayout>
  );
};

export default RouteReportPage;
