import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { SvgIcon, Divider, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Grid, Badge } from '@material-ui/core';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';

import { ReactComponent as IgnitionOnIcon } from '../public/templates/default/img/ignition-on.svg';
import { ReactComponent as IgnitionOffIcon } from '../public/templates/default/img/ignition-off.svg';

import { ReactComponent as MotionIcon } from '../public/templates/default/img/run.svg';
import { ReactComponent as StoppedIcon } from '../public/templates/default/img/stopped.svg';

import { ReactComponent as BlockedIcon } from '../public/templates/default/img/blocked.svg';
import { ReactComponent as UnblockedIcon } from '../public/templates/default/img/unblocked.svg';

import { ReactComponent as AlarmIcon } from '../public/templates/default/img/warningOn.svg';
import { ReactComponent as offAlarmIcon } from '../public/templates/default/img/warningOff.svg';


import { devicesActions } from './store';
import EditCollectionView from './EditCollectionView';
import { useEffectAsync } from './reactHelper';
import { useAttributePreference } from './common/preferences';
import { getDevices, getPosition, getGroups} from './common/selectors';
import { useTranslation } from './LocalizationProvider';
import { formatDate, formatSpeed } from './common/formatter';

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: '100%',
  },
  listInner: {
    position: 'relative',
    margin: theme.spacing(1.5, 0),
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  listItem: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  green: {
    color: theme.palette.common.green,
  },
  red: {
    color: theme.palette.common.red,
  },
  gray: {
    color: theme.palette.common.gray,
  },
  indicators: {
    marginTop: -5,
    lineHeight: 0,
  },
  secondaryListDevices: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  listItemText: {
    fontSize: '0.7em',//Insert your required size
  }
}));

const OnlineBadge = withStyles((theme) => ({
  badge: {
    color: theme.palette.common.green,
    backgroundColor: theme.palette.common.green,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const OfflineBadge = withStyles((theme) => ({
  badge: {
    color: theme.palette.common.red,
    backgroundColor: theme.palette.common.red,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
  }
}))(Badge);

const getBatteryStatus = (batteryLevel) => {
  if (batteryLevel >= 71) {
    return 'green';
  }
  if (batteryLevel >= 45 && batteryLevel <= 70) {
    return 'gray';
  }
  else{
    return 'red';
  }
  
};

const getPhoto = (item) => {
  let file_photo = (item.category === '' || item.category === undefined || !item.category) ? '/images/icon/default.svg' : `/images/icon/${item.category}.svg`;

  if (item.photo) {
    if (item.photo.length > 0 && item.photo.length < 30) {
      file_photo = `/photos/devices/${item.photo}`;
    }
  }
  return `.${file_photo}`;
}

const DeviceRow = ({ data, index, style }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  //const isAdmin = useSelector(getIsAdmin);
 // const isManager = useSelector(getIsManager);
  const groups = useSelector(getGroups);
  const speedUnit = useAttributePreference('speedUnit');

  const { items } = data;
  const item = items[index];
  const position = useSelector(getPosition(item.id));
  const showIgnition = position?.attributes.hasOwnProperty('ignition') && position.attributes.ignition;
  const showMotion = position?.attributes.hasOwnProperty('motion') && position.attributes.motion;
  const showBlocked = position?.attributes.hasOwnProperty('blocked') && position.attributes.blocked;
  const showAlarm = position?.attributes.hasOwnProperty('alarm') && position.attributes.alarm || position?.attributes.hasOwnProperty('powerCut') && position.attributes.powerCut;
  const dateTimeFormat = useAttributePreference('dateTimeFormat', 'DD/MM/YY HH:mm:ss');

  let groupName = ' ';
  if (item.groupId) {
    const idxGroup = groups.findIndex(g => g.id === item.groupId);
    if (idxGroup > -1) {
      groupName = ` |  ${groups[idxGroup].name}`;
    }
  }

  return (
    <div style={style}>
      <ListItem button key={`device_${item.id}`} className={classes.listItem} onClick={() => {
        dispatch(devicesActions.select(item));
        dispatch(devicesActions.setShowInfoBottom(true));
      }
      }>
        <ListItemAvatar>
          {item.status === 'online' ?
            <OnlineBadge
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              variant="dot">
              <Avatar style={{ backgroundColor: '#fff', padding: '5%' }} alt={item.name} src={getPhoto(item)} alt="" />
            </OnlineBadge>
            :
            <OfflineBadge
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              variant="dot">
              <Avatar style={{ backgroundColor: '#fff', padding: '5%' }} alt={item.name} src={getPhoto(item)} />
            </OfflineBadge>
          }
        </ListItemAvatar>
        <ListItemText primary={<Typography noWrap variant="inherit" style={{ color: '#000', fontSize: 12, fontWeight: 'bold', textTransform: 'uppercase' }}>
          {item.contact} {item.attributes.bucket ? ` | ${item.attributes.bucket} ` : ''}
        </Typography>} classes={{ primary: classes.listItemText }}
          secondary={(
            <div className={classes.secondaryListDevices}>
              <div>
              <Typography noWrap variant="inherit" style={{ fontSize: 11 }}> {item.name ? ` ${item.name} | ` : ''}</Typography>
              <Typography variant="inherit" style={{ fontSize: 11 }}> {`${formatSpeed(position?.speed || 0, speedUnit, t)} |`}</Typography>
              <Typography variant="inherit" style={{ flex: 1, fontSize: 11 }}> {item.lastUpdate ? `${formatDate(item.lastUpdate, dateTimeFormat)}` : t('sharedNoData')}</Typography>
                
              </div>
              <div>
                {position && (
                  <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                    {showIgnition ?
                      <Grid item>
                        <SvgIcon component={IgnitionOnIcon} style={{ height: 10, width: 10 }} />
                      </Grid>
                      :
                      <Grid item>
                        <SvgIcon component={IgnitionOffIcon} style={{ height: 10, width: 10, color: '#000' }} />
                      </Grid>
                    }
                    {showMotion ?
                      <Grid item>
                        <SvgIcon component={MotionIcon} style={{ height: 10, width: 10 }} />
                      </Grid>
                      :
                      <Grid item>
                        <SvgIcon component={StoppedIcon} style={{ height: 10, width: 10, color: '#000' }} />
                      </Grid>
                    }
                    {showBlocked ?
                      <Grid item>
                        <SvgIcon component={BlockedIcon} style={{ height: 10, width: 10, color: '#000' }} />
                      </Grid>
                      :
                      <Grid item>
                        <SvgIcon component={UnblockedIcon} style={{ height: 10, width: 10, color: '#000' }} />
                      </Grid>
                    }
                    {showAlarm ?
                      <Grid item>
                        <SvgIcon component={AlarmIcon} style={{ height: 10, width: 10 }} />
                      </Grid>
                      :
                      <Grid item>
                        <SvgIcon component={offAlarmIcon} style={{ height: 10, width: 10, color: '#000' }} />
                      </Grid>
                    }
               
                    {position.attributes.batteryLevel ?  
                      <Grid item>
                          <BatteryFullIcon className={classes[getBatteryStatus(position.attributes.batteryLevel)]} style={{ marginTop:2, height: 15, width: 15 }} />
                      </Grid>
                      : ''                      
                    }
                    <Grid item>
                      <Typography noWrap variant="inherit" style={{ color: '#000', fontSize: 12, }}>
                        {groupName}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
          )}
        />
      </ListItem>
      <Divider />
    </div>
  );
};

const DeviceView = ({ updateTimestamp, onMenuClick, filter }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const listInnerEl = useRef(null);

  const items = useSelector(getDevices);
  const [filteredItems, setFilteredItems] = useState(null);

  useEffect(() => { 
    setFilteredItems(
      filter.trim().length > 0
        ? items.filter((item) => `${item.name} ${item.uniqueId} ${item.contact}`.toLowerCase().includes(filter?.toLowerCase()))
        : items,
    );
  }, [filter, items]);

  if (listInnerEl.current) {
    listInnerEl.current.className = classes.listInner;
  }

  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      dispatch(devicesActions.refresh(await response.json()));
    }
  }, [updateTimestamp]);

  return (
    <AutoSizer className={classes.list}>
      {({ height, width }) => (
        <List disablePadding>
          <FixedSizeList
            width={width}
            height={height}
            itemCount={filteredItems.length}
            itemData={{ items: filteredItems, onMenuClick }}
            itemSize={92}
            overscanCount={10}
            innerRef={listInnerEl}>
            {DeviceRow}
          </FixedSizeList>
        </List>
      )}
    </AutoSizer>
  );
};

const DevicesList = ({ filter }) => (
  <EditCollectionView content={DeviceView} editPath="/device" endpoint="devices" disableAdd filter={filter} />
);

export default DevicesList;